import React, { Component } from 'react';
import auth from "../../Auth/auth";
import "../Create_Set/CreateSet.scss"
import {MdDelete , MdCheck} from 'react-icons/md'

import { IconContext } from "react-icons";
class ParsedCard extends Component{
    constructor(props){
        super(props);
      
        this.term = this.props.term;
        this.definition = this.props.definition;
        this.cardIndex = this.props.cardIndex;
        this.checkable = this.props.checkable;
        this.checkClick = this.props.checkClick;
        this.removeInput= this.props.removeInput;
        this.handleChange = this.props.handleChange;
        this.sample = this.props.sample;
        this.setTerm = this.setTerm.bind(this);
        this.setDefinition = this.setDefinition.bind(this);

    }

    setTerm(e)
    {
        this.term = e.target.value;
    }
    setDefinition(e)
    {
        this.definition = e.target.value;
    }
    
    


    render(){
        

        return(

            <div className="input-card"  >
                            <div className="input-card-header">
                            
                            <IconContext.Provider value={{ className: "hoverable check-icon-card" }}>
                                {this.sample ? <MdCheck onClick={() => this.checkClick(this.cardIndex)}></MdCheck> : <></>}
                         
                            </IconContext.Provider>
                            <IconContext.Provider value={{ className: "hoverable trash-icon" }}>
                     

                                <MdDelete onClick={() => this.removeInput(this.cardIndex)}></MdDelete>
                            </IconContext.Provider>
                            </div>
                            <div className="input-card-body">
                                <input className="input-field input-field-term" type="text" name={`term-${this.cardIndex}`} value={this.term} placeholder="Term" onChange={(e) => {this.handleChange(e); this.setTerm(e)}}/> 
                                <input className="input-field input-field-definition" type="text" name={`definition-${this.cardIndex}`} placeholder="Definition" value={this.definition} onChange={(e) => {
                                    
                                    this.handleChange(e); 
                                    this.setDefinition(e); 
                                    
                                 } } />
                                </div>
            </div>

        );
    
    }
}
export default ParsedCard; 

{/* <tr className="trow"><td className='divtab' ></td><td className='card' onClick={(e) => this.flip()}>

{this.state.termShow ? this.term : this.definition}

<div className="xdiv">
{this.checkable ? <img src={redX} className="redX" onClick={() => this.xClicked(this.term)} ></img> : <></>}
{this.sample ? <img src={greencheck} className="greencheck" onClick={() => this.checkClick(this.term)}></img> : <></>}

</div>
            

</td>
</tr> */}
