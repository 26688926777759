import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';


import TitleBar from '../Header-Footer/TitleBar';
import { API_URL } from '../env';
import './landing-page.scss'
import './mobile-header.scss'
import Footer from '../Header-Footer/Footer';
import female from '../images/Female-business-people-using-laptop-Stock-Photo.jpg'
import female_studying from '../images/girl_studying.jpg'
import {connect} from "react-redux"
import api from '../services/api';
import {slide as Menu} from 'react-burger-menu'
import Login from '../Login/Login'
import { withRouter } from 'react-router';
import { addQuery, removeQuery } from '../Utils/addParameter';

axios.defaults.withCredentials = true;

// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class LandingPage extends Component {
  constructor(props){
    super(props);
    
    this.state = { 
      "mobile" : false,
      "version" : props.version

    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions();
    this.landingClick.bind(this)

    // this.props.history.push(window.location.pathname + "?" +  "version=" + this.state.version);

  }

  componentDidMount()
  {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    var data = {
      "action" : "load landing " + (this.state.mobile ? " mobile" : " desktop") + " " + this.state.version,
      "version" : "" ,
       "page" : "landing"
    }
    api.logEvent(data , () => {return})
  }
  landingClick(val)
  {
    var data = {
      "action" : val,
      "version" : "" ,
       "page" : "landing"
    }
    api.logEvent(data , () => {return})
  }

  updateWindowDimensions() {
    const width = window.innerWidth;
    var popupWidth = "400px";
    if(width < 600)
    {
      this.setState({ "mobile" : true });
    }
    else{
      this.setState({ "mobile" : false });
    }
    
    
  }

  handleChange(event) {
      
      const val = event.target.value;
      this.setState({"TypedVal" : val});

      
      
    }

    showSettings (event) {
      event.preventDefault();
      

    }

render(){
if(this.state.mobile)
{


  return (
<div className='view-wrapper landing-wrapper'>

<div className="mobile-title">

<div className="mobile-landing-top-bar">
<div className="mobile-title-wrapper">
<div className="mobile-title-logo"></div>
<div className="mobile-title-text">Flippso</div>
<div className="mobile-ham-stack">
<Menu right width="50%">
<a onClick={ this.showSettings } className="menu-item--small" href=""></a>
<NavLink to="/about" className="mobile-setting-text" onClick={(event) => {this.landingClick("about clicked mobile" ); this.props.GetStarted() }}>About</NavLink> 
<NavLink to="/login" className="mobile-setting-text" onClick={(event) => {this.landingClick("login clicked mobile" ); this.props.GetStarted() }}>login</NavLink> 
</Menu>
</div>
</div>

</div>
<div className="mobile-landing-text-wrapper">
<div className="mobile-landing-bold-text">Fast,<br></br>Smart,<br></br>Flashcards </div>
<NavLink to="/login" className="mobile-landing-button" onClick={(event) => {this.landingClick("get started clicked mobile" ); this.props.GetStarted() }}>Start Studying</NavLink>
<div className="mobile-landing-sub-text">Flippso makes studying easier than ever, generating study sets from your uploaded notes. Try it today for free!</div>
</div>


</div>
<div className="mobile-landing-image"></div>






{/* */}


<Footer></Footer>   

  </div>);
  }
  else if(this.state.version == "c"){
    return(
          <div className='view-wrapper landing-wrapper'>

              <TitleBar selected="Home" ></TitleBar>

              <div className="landing-section image-1">
              <div className="landing-button-wrapper">
                <div className="landing-button-text">
                  Study, <div style={{"display" : "inline" , "color" : "#5899E2"}}>smarter</div>
                </div>
                <NavLink to="/login" className="landing-button-1" onClick={(event) => {this.landingClick(); this.props.GetStarted() }}>Get Started</NavLink> 

              </div>
              <div className="landing-transparent">

              {this.state.version == "a"? 
              <>
              <div className="landing-header-text">How It Works</div>
              <div className="landing-list-wrapper">
                <ul className="landing-list">
                  <li className="landing-list-item">
                    Create an account to make a set
                  </li>
                  <li className="landing-list-item">
                    Flippso turns your notes into study sets
                  </li>
                  <li className="landing-list-item">
                    Flippso identifes which concepts you need the most help with
                  </li>
                  <li className="landing-list-item">
                    Flippso gives recommended study material based on your personal timeline
                  </li>
                  <li className="landing-list-item">
                    Optimize your time with flashcards powered by machine learning!
                  </li>
                </ul>
              </div>
              </>:
              <>
              <div className="landing-header-text">How It Works</div>
              <div className="landing-list-wrapper">
                <ul className="landing-list">
                  <li className="landing-list-item">
                    Create flashcards manually or automatically by uploading your notes and readings
                  </li>
                  <li className="landing-list-item">
                    Flippso tracks your most missed cards and due dates to create customized recommendations
                  </li>
                  <li className="landing-list-item">
                    Spend less time preparing to study and more time learning!
                  </li>
                </ul>
              </div>
              </>

              }

              </div>

    </div>

    {/* */}




      </div>
    )

  }
  else{
    return(
      <div className='view-wrapper landing-wrapper'>

          <TitleBar selected="Home"></TitleBar>

          <div className="landing-section-b image-1-b">
            <div className="landing-button-wrapper-b">
              <div className="landing-button-text-b">
                Study, <div style={{"display" : "inline" , "color" : "#5899E2"}}>smarter</div>
              </div>
              <Login></Login>
            </div>
          </div>

          <div className="landing-info-section-b">
            <div className="landing-info-text">
              <div className="landing-info-text-header" style={{"color" : "white"}}>
                How it Works</div>
              <div className="landing-info-text-body" style={{"color" : "white"}}>
                Flippso uses educational psychology and machine learning to optimize
                your studying. Have a test in a week? Flippso identifies which concepts
                you need the most help with and provides recommendations so you can
                reach your academic goals!<br></br><br></br>
                Need to memorize a set in a flash? Upload your notes, handwritten or
                typed as a .pdf under "Generate a Set", and watch our algorithm identify
                key terms to help you study.<br></br><br></br>
                Flippso tracks your progress as you study by rating your confidence on
                flashcards or typing terms and definitions. Mark the due date of the test
                you are studying for to help our algorithm optimize your study habits to
                ace your test.<br></br><br></br>
                Review your sets on mobile for a quick refresh!
            </div>

          </div>
          <div className="landing-info-image"></div>
            
          </div>


          <div className="landing-demo-section-b">
          <div className="landing-demo-image">
              
          </div>
            <div className="landing-demo-text">
            <div className="landing-info-text-header" style={{"color" : "#5899E2"}}>
            Gain Confidence
            </div>
            <div className="landing-info-text-body" style={{"color" : "black"}}>
            View your progress as you study!<br></br><br></br>
            Choose your study method: flip, type, or quiz. We recommend starting with one
            round of "flip" to get familiar with the set, especially if it is longer than 10 terms.
            Then, move on to "quiz". Quiz shows 4 multiple choice options that you must
            choose from. Finally, move on to "type". This is generally the most challenging
            study option and demands mastery of the content. Once you can type all of
            your terms correctly, you will be on your way to your next A!<br></br><br></br>
            Studying is all about confidence. The more you study, the less nervous you will
            be on a test. So, make studying fun with Flippso. Choose one round of flip, type,
            or quiz, every day until your test. You will be unstoppable!
            </div>
       
            <div style={{"color" : "#fff"}}><NavLink to="/app" className="start-studying-button button-main" style={{"color": "#fff"}} onClick={(event) => {this.landingClick("login clicked mobile" ); this.props.GetStarted() }}>Start Studying</NavLink> </div>
          
             </div>
             
            
  
    
          </div>

          
     
 

      <Footer></Footer>   
      </div>
)
  
}
}
}


const mapDispatchtoProps = dispatch => {
  return{
    GetStarted: () => {
      dispatch({ type: "GETSTARTED"});
  }

}
}


const mapStatetoProps = state => {
  return{
    version: state.version
  }
}






export default connect(mapStatetoProps , mapDispatchtoProps)(LandingPage);



