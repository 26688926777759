import React, { Component } from 'react';
import Api from '../../../services/api'
import "../SetSelection/SetList.scss"
import "./TypeStudy.scss"

import {FaUndo} from 'react-icons/fa'
import {MdFlipToFront} from 'react-icons/md'
import ReactTooltip from 'react-tooltip'
import Footer from '../../../Header-Footer/Footer';
import Modal from 'react-awesome-modal';
import ViewSideBar from '../ViewSideBar'
import {API_URL} from '../../../env'
import axios from 'axios'
import reducer from "../../../Auth/reducer"
import TitleBar from '../../../Header-Footer/TitleBar';
import DeletePopup from '../DeletePopup'
import ViewSetHelper from '../ViewSetHelper'
// import ColorPicker from 'material-ui-color-picker'

import CheckedInput from "./CheckedInput";


import TextareaAutosize from 'react-autosize-textarea';



class TypeStudy extends Component {
    constructor(props){
        super(props);
        this.key = this.props.key;
        
        this.count = props.small ? 6 : 9;
        if(props.match == undefined)
        {
          this.setID =""
        }
        else{
          this.setID = props.match.params.id;
        }
        

        // this.pageLength = Math.ceil(props.sets.length / this.count ) 
        this.state = { "Cards" : [], "DisplayIndex" : 0 , "TypedVal" : "" , "Copy" : false , "Loading" : true , "Display" : "Term" , "Progress" : 0 , "StudyMode" : "" , "DeletePopup" :false};
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


        }


    componentDidMount(){
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      // make api call to get the cards
      Api.getCards(this.setID, (cardArray, setName) => {
        for(var i= 0 ; i < cardArray.length ; i++)
        {
          cardArray[i].MissedCount = 0;
        }
        this.setState({'Loading' : false , 'Cards' : cardArray.slice() , 'FailedCards' : cardArray , 'CorrectCards' : [] , 'SetName' : setName , "Count" : "0 / " + cardArray.length.toString()});  
      });
      
    }
    
    openDeletePopup(){
      this.setState({"DeletePopup" : true});
    }
    closeDeletePopup(){
      this.setState({"DeletePopup" :false});
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  
  
    updateWindowDimensions() {
      const width = window.innerWidth;
      var popupWidth = "400px";
      if(width < 460)
      {
          popupWidth = "300px";
      }
      
      this.setState({ "popupWidth" : popupWidth });
    }

    handleChange(event) {
        
        const val = event.target.value;
        this.setState({"TypedVal" : val});

        
        
      }
    changeCard(i)
      {
        const cardArray = this.state.Cards;
        if(i + this.state.DisplayIndex < cardArray.length && this.state.DisplayIndex + i >= 0)
        {
          this.setState((prevState) => ({'DisplayIndex' : prevState.DisplayIndex + i }));
        }
        
      }

      submit(val)
      {
        var match;
        val = val.replace(/[^a-zA-Z0-9]/g , '');
        if(this.state.Display == "Term")
        {
          match = val.toLowerCase() === this.state.FailedCards[0].definition.replace(/[^a-zA-Z0-9]/g , '').toLowerCase()
        }
        else{
          match = val.toLowerCase() === this.state.FailedCards[0].term.replace(/[^a-zA-Z0-9]/g , '').toLowerCase()
        }

        if(val.length <= 1 && this.state.Copy)
        {
          match = true;
        }
        if(match)
        {
          var CorrectCards = this.state.CorrectCards;
          var FailedCards = this.state.FailedCards;
          var card = FailedCards.splice(0 , 1);
          CorrectCards.push(card[0]);
          if(FailedCards.length == 0)
          {
            Api.updateAccuracy({"cards" : this.state.CorrectCards , "set_id" : this.setID} , () => {return})
            this.setState((prevState) => ({"FailedCards": [{"term" : "You've finished the set, would you like to review it again?" , "definition" : "You've finished the set, would you like to review it again?" }] , "Copy" : false , "TypedVal" : "" , "Progress" : 100 , "StudyMode" : "hold" }));

          }
          else{
            this.setState((prevState) => ({"FailedCards": FailedCards , "CorrectCards": CorrectCards , "Copy" : false , "TypedVal" : "" , "Progress" : Math.floor(100 * (prevState.Cards.length - prevState.FailedCards.length) / (prevState.Cards.length) ) , "Count" : (prevState.CorrectCards.length).toString() + " / " + prevState.Cards.length.toString() }));
          }


        }
        else{
          var FailedCards = this.state.FailedCards;
          FailedCards[0].MissedCount += 1
          this.setState({"Copy": true , "FailedCards": FailedCards });
        }
      }

      skip(){
        var FailedCards = this.state.FailedCards;
        var card = FailedCards.splice(0 , 1);
        FailedCards.push(card[0])
        this.setState({"FailedCards": FailedCards , "Copy" : false});
        
      }
      switchTermDef(){
        
        this.setState((prevState) => ({"Display" : prevState.Display == "Term" ? "Definition" : "Term"}) )
      }
      restart()
      {
        this.setState((prevState) => ({ "Copy" : false , "StudyMode" : "" , "FailedCards" : prevState.Cards.slice(),  "Progress" : 0 , "Count" : "0 / " +  prevState.Cards.length.toString() }))
      }
      showAnswer()
      {
        this.setState({"Copy": true });
      }
 

    render(){
      if(this.state.Loading)
      {
        return(
          <div className='view-wrapper'>
            <TitleBar ></TitleBar>
            <h3>Loading your set please wait</h3>
            <Footer></Footer>
          </div>
          
        );
      }
      else{
        return(
          <>
          <TitleBar selected="My Sets" ></TitleBar>
          <div className="card-grid">
            <div className="side-bar-wrapper">
            <ViewSideBar SetName={this.state.SetName} SetID={this.SetID} openDeletePopup={this.openDeletePopup.bind(this)} setID ={this.setID} Mode="Type" Progress={this.state.Progress} Count={this.state.Count} Display={this.state.Display}></ViewSideBar>
          </div>
          <div className="set-top-bar-wrapper">
            <div className="flip-switch-wrapper" data-tip data-for="flipTip">
                <MdFlipToFront className={"hoverable flip-switch-button"}  onClick={(e) => this.switchTermDef()}></MdFlipToFront>
            </div>
            <div className="flip-reset-wrapper" data-tip data-for="resetTip">
            <FaUndo className={"hoverable flip-reset-button"}  onClick={(e) => this.restart()}></FaUndo>  
            </div>   

            <ReactTooltip id="flipTip" place="top"  effect="solid" wrapper="span">Flip whether term or definition is displayed first</ReactTooltip>
            <ReactTooltip id="resetTip" place="top"  effect="solid" wrapper="span">Restart</ReactTooltip>   
    

          </div>

           
            <div className="cards">

                  <div className="card">
                      <div className="card__side card__side--front card__side--front-1 disable-rotate">
                          <div className="card__description" style={{"font-size" : this.state.Display == "Term" ? ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].term) : ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].definition)}}>{this.state.Display == "Term" ? this.state.FailedCards[0].term : this.state.FailedCards[0].definition}</div>
                      </div>   
                  </div>
                  <div className="type-options">
                      
                      {this.state.StudyMode == "hold" ? <></> : <div className="hoverable type-option-button" style={{"backgroundColor" : '#FCAB10'}} onClick={() => this.skip()}>Skip</div>}  
                      {this.state.StudyMode == "hold" ? <></> : <div className="hoverable type-option-button" style={{"backgroundColor" : '#FCAB10'}} onClick={() => this.showAnswer()}>Help</div>}  
                      {this.state.StudyMode != "hold" ? <></> : <div className="hoverable type-option-button" style={{"backgroundColor" : 'red'}} onClick={() => (this.props.history.push("/viewset/" + this.setID))}>No</div>}  
                      {this.state.StudyMode != "hold" ? <></> : <div className="hoverable type-option-button" style={{"backgroundColor" : '#20c294'}} onClick={() => this.restart()}>Yes</div>} 
                                 
                  </div>
              </div>
              <div className="type-study-input-wrapper">
                  {!this.state.Copy ? 
                  <div className="type-study-input"  >
                          <div className="check-card-body">
                              <TextareaAutosize className="input-field type-study-input-field" type="text"  value={this.state.TypedVal} placeholder={this.state.Display == "Term" ? "Definition" : "Term"} onChange={(e) => {this.handleChange(e);}}/> 
                          </div> 
                          <div className="hoverable type-button" style={{"backgroundColor" : '#20c294'}} onClick={(e) => this.submit(this.state.TypedVal)}>{"Submit"}</div>        
                  </div>
                  :
                  <CheckedInput  check={this.state.Display == "Term" ? this.state.FailedCards[0].definition : this.state.FailedCards[0].term} submit={this.submit.bind(this)}></CheckedInput>
                  }
              </div>
          </div>

          <DeletePopup DeletePopup={this.state.DeletePopup} closeDeletePopup={() => this.closeDeletePopup()} SetID={this.setID}></DeletePopup>
  
           </>

          

      )
      }

        

    }      
    



    
  };
  
  export default TypeStudy;