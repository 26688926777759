import React, { Component } from 'react';
import auth from "../../../Auth/auth";
import Api from "../../../services/api"
import { NavLink } from 'react-router-dom';
import "./SetSelection.scss"
import "./SetSelect.scss"
import "./TutorialPopup.scss"
import '@brainhubeu/react-carousel/lib/style.css';
import Footer from '../../../Header-Footer/Footer';
import EditTopic from './EditTopic';
import SetList from './SetList';
import Topic from './Topic';
import {connect} from "react-redux"
import {IoIosColorPalette , IoIosFitness , IoIosFlash , IoIosFlask , IoIosGitNetwork , IoIosJet , IoIosMedical , IoIosNuclear , IoIosPaper , IoIosPerson , IoIosPlanet , IoIosRainy , IoIosPulse , IoIosRestaurant , IoIosRocket , IoIosSchool 
    , IoIosStats , IoIosTrendingUp , IoMdBasketball, IoMdContacts  } from 'react-icons/io'
import {FaPencilAlt  } from 'react-icons/fa'
import Modal from 'react-awesome-modal';
import Carousel, {  slidesToShowPlugin  }  from '@brainhubeu/react-carousel';
import AdComponent from '../../../Utils/adComponent';
import AdSense from 'react-adsense';
import { DragDropContext, Draggable,Droppable } from 'react-beautiful-dnd';


class SetSelection extends Component {
    constructor(props){
        super(props);
        this.key = this.props.key;

        this.editCalled = false;

        this.state = {"popupWidth": "400px" , "popupHeight" : "450px",  "Tutorial" : props.tutorial , "Loading" : true ,"NoTopic" : {"Sets": [] , "Topic" : "No Topic" , "Length" : 0}, "Sets" : [] , "Topics" : [] , "Selection" : "" , "TopicIcon" : "" , "TopicColor" : "rgba(0 , 0 , 0, .5)" , "TopicName" : "" ,  "carouselOffset" : 150 , "circleSize" : 28 , "Small" : false };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);

        }
          

        
    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        Api.getSets( (topicArray , setArray) => {         
            topicArray.splice(0,0,{'Topic' : "Recommended", 'Topic_Color' : "#5899E2" , 'Topic_Symbol' : "IoIosSchool" ,  'Topic_Class' : "" });
            
            
            this.props.SetReduceTopics(topicArray);   
            this.props.SetReduceSets(setArray);
            this.setState({"Topics" : topicArray })   ;
        } , (err) => { return; });
              
    }
    
  
    componentDidUpdate(prevProps) {
        if(this.state.Topics.length.toString() != this.props.Topics.length.toString()) 
        {
            this.setState({"Topics" : this.props.Topics })
        }
      }  

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
  dontShowAgainPopup(){
    this.props.SetReduceTutorial(false);
    
    Api.removeTutorial( () => this.setState({"Tutorial" : false}));  
    
  }
  closeTutorialPopup(){
    this.setState({"Tutorial" : false});
  }



updateWindowDimensions() {
const width = window.innerWidth;
var offset;
var circleSize = 28;
var small = false;
var popupWidth = "450px";
var popupHeight = "450px";
if(width < 500)
{
    circleSize=20;
    small = true;
    popupWidth = "300px";
    popupHeight= "400px";
}
if(width < 400){
    offset = 75;
    circleSize=18;
    popupWidth = "200px";
    popupHeight= "300px";
    
}
else if(width < 600){
    offset = 25;
    popupWidth = "350px";
    popupHeight= "400px";

}
else if(width < 700){
    offset = 50;
    popupWidth = "350px";
    popupHeight= "400px";
}
else if(width < 1000){
    offset = 100;
}
else {
    offset = 150;
}
this.setState({ "carouselOffset" : offset , "circleSize" : circleSize , "Small" : small , "popupWidth" : popupWidth , "popupHeight" : popupHeight});
}

   

    getTopicIcon(topic)
    {

        if(topic.Topic_Symbol == "IoIosColorPalette")
        {
            return(<IoIosColorPalette></IoIosColorPalette>)
        }
        else if(topic.Topic_Symbol =="IoIosFitness"){
            return(<IoIosFitness></IoIosFitness>)
        }
        else if(topic.Topic_Symbol == "IoIosFlash"){
            return(<IoIosFlash></IoIosFlash>)
        }
        else if(topic.Topic_Symbol == "IoIosFlask"){
            return(<IoIosFlask></IoIosFlask>)
        }
        else if(topic.Topic_Symbol == "IoIosGitNetwork"){
            return(<IoIosGitNetwork></IoIosGitNetwork>)
        }
        else if(topic.Topic_Symbol == "IoIosJet"){
            return(<IoIosJet></IoIosJet>)
        }
        else if(topic.Topic_Symbol == "IosMedical"){
            return(<IoIosMedical></IoIosMedical>)
        }
        else if(topic.Topic_Symbol == "IoIosNuclear"){
            return(<IoIosNuclear></IoIosNuclear>)
        }
        else if(topic.Topic_Symbol == "IoIosPaper"){
            return(<IoIosPaper></IoIosPaper>)
        }
        else if(topic.Topic_Symbol == "IoIosPerson"){
            return(<IoIosPerson></IoIosPerson>)
        }
        else if(topic.Topic_Symbol == "IoIosPlanet"){
            return(<IoIosPlanet></IoIosPlanet>)
        }
        else if(topic.Topic_Symbol == "IoIosRainy"){
            return(<IoIosRainy></IoIosRainy>)
        }
        else if(topic.Topic_Symbol == "IoIosPulse"){
            return(<IoIosPulse></IoIosPulse>)
        }
        else if(topic.Topic_Symbol == "IoIosResteraunt"){
            return(<IoIosRestaurant ></IoIosRestaurant>)
        }
        else if(topic.Topic_Symbol == "IoIosRocket"){
            return(<IoIosRocket></IoIosRocket>)
        }
        else if(topic.Topic_Symbol == "IoIosSchool"){
            return(<IoIosSchool></IoIosSchool>)
        }
        else if(topic.Topic_Symbol == "IoIosStats"){
            return(<IoIosStats></IoIosStats>)
        }
        else if(topic.Topic_Symbol == "IoIosTrendingUp"){
            return(<IoIosTrendingUp></IoIosTrendingUp>)
        }
        else if(topic.Topic_Symbol == "IoMdBasketball"){
            return(<IoMdBasketball></IoMdBasketball>)
        }
        else if(topic.Topic_Symbol == "IoMdContracts"){
            return(<IoMdContacts></IoMdContacts>)
        }
        else if(topic.Topic_Symbol == ""){
            return(<></>)
        }


        
    }

    changeSelection(selection)
    {
        if(this.state.Selection === selection)
        {
            selection = "";
        }
        this.setState({"Selection" : selection , "PrevTopic" : "" , "TopicName" : ""});



    }


    addTopic(){
        var values = this.state.Topics;
        values.push({"Topic" : "" , "Topic_Color" : "#5899E2" , "Topic_Symbol" : "" , "Sets" : [] , "Length" : 0});
        this.setState({  "Topics" : values   });
    }
    
    getselectionSection()
    {

        if(this.state.Selection == "")
        {
            return(
                <div className='button-wrapper' >
                <button className="hoverable topic-button-plus set-list-buttons button-main" onClick={ () => this.changeSelection("add-topic")}>Create Topic</button>
                </div  >
            )
        }
        else if(this.state.Selection == "add-topic")
        {
    
            
        }
        else{

            var sets = this.state.Sets.filter(set => set.Topic == this.state.Selection);

            return(
                <SetList sets={sets} selection={this.state.Selection} small={this.state.Small}></SetList>

                
            )
        }


        
    }

    deleteTopic(itemNumber)
    {
        var topic = this.state.Topics[itemNumber].Topic;
        if(topic == "")
        {
            var values = this.state.Topics;
            values.splice(itemNumber , 1);
            this.setState({  "Topics" : values   });

        }
        else{
            Api.deleteTopic({ 'topic_name': this.state.Topics[itemNumber].Topic },
            (res) => {
                    var values = this.state.Topics;
                    values.splice(itemNumber , 1);

                    this.setState({  "Topics" : values   });
                    this.props.SetReduceTopics(values);
                    this.props.ChangeReduceTopic({"TopicName" : "No Topic" } , topic);
            } , (err) => {

                this.setState({"TopicNameError" : err.response.data.Message})
            })
        }
   
    }
    handleOnDragEnd(event)
    {
        
        // var values = this.state.values;
        if(event.source == null || event.destination == null || event.source.droppableId.split("-")[1] == "" ||event.source.droppableId.split("-")[1] == ""  )
        {
            return;
        }

        var setArray = [...this.props.Sets]
        var change_set;
        for (var i =  setArray.length - 1; i >= 0; --i) {

            if (setArray[i].SetID == event.draggableId.split("-")[1]) {
                setArray[i].Topic = event.destination.droppableId.split("-")[1];
                change_set = setArray[i];
            }
        }


        Api.updateSetTopic({"SetId" : change_set.SetName ,"SetId" : change_set.SetID , "Topic" : event.destination.droppableId.split("-")[1]} );
        this.props.SetReduceSets(setArray);

        // [values[result.source.index] , values[result.destination.index]] = [values[result.destination.index],values[result.source.index] ] ;

        // this.setState(prevState => ({  values: values   }));
        

    }
    render()
    {

        if(this.state.loading)
        {
            return (<h3>Loading your sets please wait</h3> )
        }
        else{
            return( 
                <div className='view-wrapper create-wrapper'>



                    <div className="set-select-grid">
                        <div className="top-row">
                            <div className="header-text">My topics</div>
                            <button className="hoverable button-main button-spot add-topic-button" onClick={ () => this.addTopic() }>Add topic</button>
                        </div>
                        <DragDropContext onDragEnd={this.handleOnDragEnd}>
                        <div className="topic-list">
                                {this.state.Topics.map(( topic , key ) => (
                                    <Topic Key={key} key={topic.Topic} TopicColor={topic.Topic_Color} TopicName={topic.Topic} TopicIcon={topic.Topic_Symbol} Editable={topic.Topic == ""} deleteTopic={this.deleteTopic.bind(this)}/>
                                        ))}
                        </div>
                        <div className="unassigned-sets">
                            <div className="header-text">Unassigned Sets</div>
                            <SetList TopicName="No Topic" Sets={this.state.NoTopic.Sets}></SetList>
                        </div>


                        </DragDropContext >    
                    </div>
                </div>

                );
        }     
      
    }
  };
  const mapStatetoProps = state => {
    return{
      tutorial: state.tutorial,
      Topics : state.Topics,
      Sets : state.Sets
    }
  }
  
  const mapDispatchtoProps = dispatch => {
    return{

    SetReduceTutorial: (tut) => {
      dispatch({ type: "SETTUTORIAL" , tutorial : tut});
    },
    SetReduceTopics: (tut) => {
        dispatch({ type: "SETTOPICS" , Topics : tut});
      },
    SetReduceSets: (tut) => {
    dispatch({ type: "SETSETS" , Sets : tut});
    },
    ChangeReduceTopic: (top , prev) => {
        dispatch({ type: "EDITTOPIC" , Topic : top , Prev : prev});
    }

  
  }
  } 
  
  
  
  
  
  
  
  
  
  export default connect(mapStatetoProps , mapDispatchtoProps)(SetSelection);
