import React, { Component } from 'react';
import auth from "../Auth/auth";
import axios from 'axios';
import logo from "../images/img_techucateNoWhite.png"; 
import "./landing_page.css";
import "./forgot-reset.scss"
import TitleBar from '../Header-Footer/TitleBar';
import { API_URL } from '../env';
import Footer from '../Header-Footer/Footer';
import { NavLink } from 'react-router-dom';
axios.defaults.withCredentials = true;
// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class Login extends Component {
  constructor(props){
    super(props);
    this.state = { 
   

        "Email": '',
        "ResetSent": false
        

      


    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  
  handleChange(event) {
    event.preventDefault();

    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  

    
    const data = {

      email: this.state.Email,

    }
      
      axios.post(API_URL + "/reset/forgotpassword", data )

      this.setState({"ResetSent" : true});
    
    }
  



render(){

  return (
  <>

<TitleBar selected="Create"></TitleBar>




<div className="reset-body"> 

 
  <div className="reset-form-container ">

        {this.state.ResetSent ? <div className="form-header reset">Reset Email Sent!</div> : 
          
        
        <>
       
        <div className="form-header">Forgot Your Password?</div>
        <div className="reset-subtext">Enter your email below to be sent a reset link</div>
        <input type="text" className="input reset-input input-spacing" placeholder="Email" name="Email" value={this.state.email} onChange={this.handleChange} required />


        <button className="landing-button reset-form-button button-main" onClick={this.handleSubmit}>Send Email</button> </>}
    

  </div>
</div>
 
 
<Footer></Footer>   
  </>);
}
}












export default Login;





