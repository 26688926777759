import React, { Component } from 'react';
import auth from "../Auth/auth";
import axios from 'axios';
import logo from "../images/img_techucateNoWhite.png"; 
import "./landing_page.css";
import TitleBar from '../Header-Footer/TitleBar';
import { API_URL } from '../env';
import Footer from '../Header-Footer/Footer';
import { NavLink } from 'react-router-dom';
axios.defaults.withCredentials = true;
// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class Unsubscribe extends Component {
  constructor(props){
    super(props);
  
    if(props.match == undefined)
    {
      this.reset_id = "";
    }
    else{
      this.reset_id = props.match.params.id;
    }


    this.state = { 
   

        "email": '',
        "valid" : true,

    
      "UsernameError" : {
        "Text" : "",
        "Status" : false
      }

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }


  componentDidMount(){

  }

  
  handleChange(event) {
    event.preventDefault();
    if(event.target.name == "email")
    {
      this.setState({"UsernameError" : {"Text" : "" , "Status" : false} } );
    }
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  

    if(this.state.email === "" )
    {
      this.setState({      "UsernameError" : {
        "Text" : "Email cannot be empty",
        "Status" : true
      }})
    }
    else{

      const data = {
        email : this.state.email
  
      }
        
        axios.post(API_URL + "/reset/unsubscribe", data )
        .then(res => {
            this.setState({"UsernameError" : {"Text" : "Successfully Unsubscribed!" , "Status" : true}});
          
        }
          )
        .catch(err => {
            console.log(err)
            this.setState({"UsernameError" : {"Text" : "Error Unsubscribing" , "Status" : true}}  
            );
        })

    }
    

    
    }
  



render(){


    return (

    <>
    
    <TitleBar selected="Create"></TitleBar>
    
    
    <div className="reset-body "> 
    
    
            <div className="reset-form-container">
        
                    <div className="form-header">Unsubscribe from Marketing Emails</div>
                    <div className="login-subtext reset-subtext">Enter your email below to unsubscribe, if it's on our list we'll remove it</div>
                    <input type="text" className="input reset-input input-spacing" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} required />
                    {this.state.UsernameError.Status ? <div>{this.state.UsernameError.Text}</div> : <></>}

    
                
    
                    <button className="landing-button reset-form-button button-main" onClick={this.handleSubmit}>Unsubscribe</button>
                
        
        </div>
    </div>
    
    
    <Footer></Footer>   
    </>);


  
  
  }
}












export default Unsubscribe;





