import React, { Component } from '../../../node_modules/react';

import "./ViewSet.scss"
import "./Circular_Progress.css"

import { NavLink } from 'react-router-dom';
import {FiBookOpen} from 'react-icons/fi'
import {FaPencilAlt   , FaArchive} from 'react-icons/fa'
import {MdDelete } from 'react-icons/md'
import './ViewSideBar.scss'
import { CircularProgressbar } from 'react-circular-progressbar';





class ViewSideBar extends Component {

    constructor(props)
    {
      super(props);
    



      this.state = {"View" : props.View , "SetName" : props.SetName , "Progress" : 80 , "DueDate": props.DueDate , "LastProgress" : props.LastProgress , "LastDate" : props.LastDate , "Mode" : props.Mode , "Progress" : props.Progress , "Count" : props.Count , "Display" : props.Display}
      this.getBodySelection = this.getBodySelection.bind(this);
      this.openDeletePopup = props.openDeletePopup;
      this.studyChange = props.studyChange;
      this.setID = props.setID;
  
    }
    componentDidUpdate(prevProps) {
        if(this.props.Progress !== prevProps.Progress || this.props.Count !== prevProps.Count || this.props.Display !== prevProps.Display ) 
        {
          this.setState({"Progress" : this.props.Progress , "Count" : this.props.Count , "Display" : this.props.Display})
        }
      }  
    getBodySelection(){
            if(this.state.Mode == "")
            {
                if(this.state.Progress == "0")
                {
                        return(  <>                    
                                </>)     
                }
                return(<div className="side-bar-charts"> 
                        { this.state.DueDate ?                      
                        <div className="side-bar-charts-text">
                                Due Date: {this.state.DueDate}
                        </div> : <></>

                        } 
                        { this.state.LastDate ? 
                        <>                     
                        <div className="side-bar-charts-text">
                                Your Last Accuracy
                        </div>
                        <div className="side-bar-prog">
                                <CircularProgressbar className="prog-bar-direct" value={this.state.LastProgress} text={`${this.state.LastProgress.toString()}%`} />
                        </div>
                        <div className="side-bar-charts-text">
                                Last study session: <div className="green-text">{this.state.LastDate}</div>
                        </div> 
                        </>: 
                        <div className="side-bar-charts-text">
                                Chose a method below and we'll keep track of how you do to help you master the set.
                        </div>

                        } 
                  
                        

                        </div>)
            }
            else if(this.state.Mode == "Flip")
            {
                return(<div className="side-bar-charts">  
                        <div className="side-bar-charts-text">
                                Set Progress
                        </div>
                        <div className="side-bar-prog">
                                <CircularProgressbar className="prog-bar-direct" value={this.state.Progress} text={`${this.state.Progress.toString()}%`} />
                        </div>
                        <div className="side-bar-charts-text">
                                Cards Correct
                        </div>
                        <div className="side-bar-charts-text">
                                {this.state.Count}
                        </div>
                        <div className="side-bar-charts-text">
                                Showing {this.state.Display}
                        </div>
                
                        </div>)
            }
            else if(this.state.Mode == "Type")
            {
                return(<div className="side-bar-charts">  
                        <div className="side-bar-charts-text">
                                Set Progress
                        </div>
                        <div className="side-bar-prog">
                                <CircularProgressbar className="prog-bar-direct" value={this.state.Progress} text={`${this.state.Progress.toString()}%`} />
                        </div>
                        <div className="side-bar-charts-text">
                                Cards Correct
                        </div>
                        <div className="side-bar-charts-text">
                                {this.state.Count}
                        </div>
                        <div className="side-bar-charts-text">
                                {this.state.Display} First
                        </div>
                
                        </div>)
            }
            else if(this.state.Mode == "Quiz")
            {
                return(<div className="side-bar-charts">  
                <div className="side-bar-charts-text">
                        Set Progress
                </div>
                <div className="side-bar-prog">
                        <CircularProgressbar className="prog-bar-direct" value={this.state.Progress} text={`${this.state.Progress.toString()}%`} />
                </div>
                <div className="side-bar-charts-text">
                        Cards Correct
                </div>
                <div className="side-bar-charts-text">
                        {this.state.Count}
                </div>
                <div className="side-bar-charts-text">
                        {this.state.Display} First
                </div>
        
                </div>)
            }
            return(<></>)
            
    }
    render(){

        
        return(
        <div className="side-bar-container">
                <div className="options-grid">
                        <div className="set-name-side">{this.state.SetName}</div>
                </div>
                <div className="options-grid-icons">
                        {/* <div className={"hoverable study-icon set-icon " + this.state.StudyIconClass}>
                                        <FiBookOpen onClick={() => this.toggleStudyMode()}></FiBookOpen>     
                        </div> */}
                        <div className={"hoverable edit-set-icon set-icon"}>
                                <NavLink to={"/createset/" + this.setID} >
                                        <FaPencilAlt className={"pencil-icon-direct"} style={{"fontSize" : "16pt" , "color" : "white"}} ></FaPencilAlt>
                                </NavLink>  
                        </div>
                        {/* <div className={"hoverable edit-set-icon set-icon"}>
                                <FaArchive className={"pencil-icon-direct"} style={{"fontSize" : "20pt"}} onClick={() => this.ChangeSelection("TypeStudy")}></FaArchive>
                        </div> */}

                        <div className={"hoverable delete-set-icon set-icon"}>
                                        <MdDelete className={"delete-icon-direct"} style={{"fontSize" : "20pt"}} onClick={(e) => this.openDeletePopup()}></MdDelete>        
                        </div>
                </div>

                {this.getBodySelection()}
    
                <div className="side-bar-charts-text">
                        How would you like to study? 
                </div>
                {this.state.Mode != "Flip" ? <NavLink to={"/flipstudy/" + this.setID} className="hoverable sets-option-button"><div className="hoverable sets-option-button" >Flip</div></NavLink> : <></>} 
                {/* {this.state.Mode != "Quiz" ? <NavLink to={"/typestudy/" + this.setID} className="hoverable sets-option-button"><div className="hoverable sets-option-button" >Quiz</div></NavLink> : <></>}  */}
                {this.state.Mode != "Type" ? <NavLink to={"/typestudy/" + this.setID} className="hoverable sets-option-button"><div className="hoverable sets-option-button" >Typing</div></NavLink> : <></>}
                {this.state.Mode != "Quiz" ? <NavLink to={"/quizstudy/" + this.setID} className="hoverable sets-option-button"><div className="hoverable sets-option-button" >Quiz</div></NavLink> : <></>}
                {this.state.Mode != "" ? <NavLink to={"/viewset/" + this.setID} className="hoverable sets-option-button"><div className="hoverable sets-option-button" >View Set</div></NavLink> : <></>} 
           
    
                

        </div>
        );

    }
}

    export default ViewSideBar


