import {IoIosColorPalette , IoIosFitness , IoIosFlash , IoIosFlask , IoIosGitNetwork , IoIosJet , IoIosMedical , IoIosNuclear , IoIosPaper , IoIosPerson , IoIosPlanet , IoIosRainy , IoIosPulse , IoIosRestaurant , IoIosRocket , IoIosSchool 
    , IoIosStats , IoIosTrendingUp , IoMdBasketball, IoMdContacts  } from 'react-icons/io'
import React from 'react'

class TopicHelpers
{
  getOptions()
  {
    const selectOptions = [
        {name: "TopicIcon" , value: "" , label: <div className="select-div">None</div>},
        {name: "TopicIcon" , value: "IoIosColorPalette" , label:  <div className="select-icon"><IoIosColorPalette></IoIosColorPalette></div>},
        {name: "TopicIcon" , value: "IoIosFitness" , label:<div className="select-icon"><IoIosFitness></IoIosFitness></div>},
        {name: "TopicIcon" , value: "IoIosFlash" , label: <div className="select-icon"><IoIosFlash></IoIosFlash></div>},
        {name: "TopicIcon" ,value: "IoIosFlask" , label: <div className="select-icon"><IoIosFlask></IoIosFlask></div>},
        {name: "TopicIcon" , value: "IoIosGitNetwork" , label: <div className="select-icon"><IoIosGitNetwork></IoIosGitNetwork></div>},
        {name: "TopicIcon" , value: "IoIosJet" , label: <div className="select-icon"><IoIosJet></IoIosJet></div>},
        {name: "TopicIcon" , value: "IoIosMedical" , label: <div className="select-icon"><IoIosMedical></IoIosMedical></div>},
        {name: "TopicIcon" , value: "IoIosNuclear" , label: <div className="select-icon"><IoIosNuclear></IoIosNuclear></div>},
        {name: "TopicIcon" , value: "IoIosPerson" , label: <div className="select-icon"><IoIosPerson></IoIosPerson></div>},
        {name: "TopicIcon" , value: "IoIosPlanet" , label: <div className="select-icon"><IoIosPlanet></IoIosPlanet></div>},
        {name: "TopicIcon" , value: "IoIosRainy" , label: <div className="select-icon"><IoIosRainy></IoIosRainy></div>},
        {name: "TopicIcon" , value: "IoIosResteraunt" , label: <div className="select-icon"><IoIosRestaurant></IoIosRestaurant></div>},
        {name: "TopicIcon" , value: "IoIosRocket" , label: <div className="select-icon"><IoIosRocket></IoIosRocket></div>},
        {name: "TopicIcon" , value: "IoIosSchool" , label: <div className="select-icon"><IoIosSchool></IoIosSchool></div>},
        {name: "TopicIcon" , value: "IoIosStats" , label: <div className="select-icon"><IoIosStats></IoIosStats></div>},
        {name: "TopicIcon" , value: "IoIosTrendingUp" , label: <div className="select-icon"><IoIosTrendingUp></IoIosTrendingUp></div>},
        {name: "TopicIcon" , value: "IoMdBasketball" , label: <div className="select-icon"><IoMdBasketball></IoMdBasketball></div>},
        {name: "TopicIcon" , value: "IoMdContacts" , label: <div className="select-icon"><IoMdContacts></IoMdContacts></div>}
        
    
    ]
    return selectOptions;
  }
   getTopicIcon(topic)
    {
    
        if(topic == "IoIosColorPalette")
        {
            return(<IoIosColorPalette className="topic-icon-direct"></IoIosColorPalette>)
        }
        else if(topic =="IoIosFitness"){
            return(<IoIosFitness className="topic-icon-direct"></IoIosFitness>)
        }
        else if(topic == "IoIosFlash"){
            return(<IoIosFlash className="topic-icon-direct"></IoIosFlash>)
        }
        else if(topic == "IoIosFlask"){
            return(<IoIosFlask className="topic-icon-direct"></IoIosFlask>)
        }
        else if(topic == "IoIosGitNetwork"){
            return(<IoIosGitNetwork className="topic-icon-direct"></IoIosGitNetwork>)
        }
        else if(topic == "IoIosJet"){
            return(<IoIosJet className="topic-icon-direct"></IoIosJet>)
        }
        else if(topic == "IoIosMedical"){
            return(<IoIosMedical className="topic-icon-direct"></IoIosMedical>)
        }
        else if(topic == "IoIosNuclear"){
            return(<IoIosNuclear className="topic-icon-direct"></IoIosNuclear>)
        }
        else if(topic == "IoIosPaper"){
            return(<IoIosPaper className="topic-icon-direct"></IoIosPaper>)
        }
        else if(topic == "IoIosPerson"){
            return(<IoIosPerson className="topic-icon-direct"></IoIosPerson>)
        }
        else if(topic == "IoIosPlanet"){
            return(<IoIosPlanet className="topic-icon-direct"></IoIosPlanet>)
        }
        else if(topic == "IoIosRainy"){
            return(<IoIosRainy className="topic-icon-direct"></IoIosRainy>)
        }
        else if(topic == "IoIosPulse"){
            return(<IoIosPulse className="topic-icon-direct"></IoIosPulse>)
        }
        else if(topic == "IoIosResteraunt"){
            return(<IoIosRestaurant className="topic-icon-direct"></IoIosRestaurant>)
        }
        else if(topic == "IoIosRocket"){
            return(<IoIosRocket className="topic-icon-direct"></IoIosRocket>)
        }
        else if(topic == "IoIosSchool"){
            return(<IoIosSchool className="topic-icon-direct"></IoIosSchool>)
        }
        else if(topic == "IoIosStats"){
            return(<IoIosStats className="topic-icon-direct"></IoIosStats>)
        }
        else if(topic == "IoIosTrendingUp"){
            return(<IoIosTrendingUp className="topic-icon-direct"></IoIosTrendingUp>)
        }
        else if(topic == "IoMdBasketball"){
            return(<IoMdBasketball className="topic-icon-direct"></IoMdBasketball>)
        }
        else if(topic == "IoMdContacts"){
            return(<IoMdContacts className="topic-icon-direct"></IoMdContacts>)
        }
        else {
            return(<div className="icon-placeholder"></div>)
        }
    
    
        
    }
    
}

export default new TopicHelpers()