import React, { Component } from '../../../../node_modules/react';

import axios from 'axios'

import TitleBar from '../../../Header-Footer/TitleBar';
import {API_URL} from '../../../env'


import auth from '../../../Auth/auth'
import { CircularProgressbar } from 'react-circular-progressbar';
import { IconContext } from "react-icons";
import ProgressBar from 'react-bootstrap/ProgressBar'
import {MdBook , MdCheck , MdClose} from 'react-icons/md'
import {CardTable} from '../CardTable'
import { NavLink } from 'react-router-dom';
import {FiBookOpen} from 'react-icons/fi'
import {FaEclispe} from 'react-icons/fa'
import {MdDelete } from 'react-icons/md'

import Footer from '../../../Header-Footer/Footer';
import Modal from 'react-awesome-modal';
import ViewSideBar from '../ViewSideBar'
import { Progress } from 'semantic-ui-react'
import Api from '../../../services/api'
import './FlipStudy.scss'
import {FaUndo} from 'react-icons/fa'
import {MdFlipToFront} from 'react-icons/md'
import ReactTooltip from 'react-tooltip'
import DeletePopup from '../DeletePopup'
import ViewSetHelper from '../ViewSetHelper'

import reducer from "../../../Auth/reducer"



axios.defaults.withCredentials = true;

class FlipStudy extends Component {

  constructor(props)
  {
    super(props);
  
    if(props.match == undefined)
    {
      this.setID =""
    }
    else{
      this.setID = props.match.params.id;
    }
  
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {"DeletePopup": false , 'SetName': '' , 'Loading' : true , 'Cards' : [] ,  "Count" : "" , "Display" : "Term" , 'FailedCards' : [] , 'CorrectCards' : [] , 'DisplayIndex' : 0 , 'StudyMode' : '' , "Progress" : 0 ,  "popupWidth" : "400px" , "Mode" : "Term" };
 


  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // make api call to get the cards
    Api.getCards(this.setID, (cardArray, setName) => {
      for(var i= 0 ; i < cardArray.length ; i++)
      {
        cardArray[i].MissedCount = 0;
      }
      this.setState({'Loading' : false , 'Cards' : cardArray.slice() , 'FailedCards' : cardArray , 'SetName' : setName , "Count" : "0 / " + cardArray.length.toString()});  
    });
    
  }
 

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }


  updateWindowDimensions() {
    const width = window.innerWidth;
    var popupWidth = "400px";
    if(width < 460)
    {
        popupWidth = "300px";
    }
    
    this.setState({ "popupWidth" : popupWidth });
  }

  changeCard(i)
  {
    const cardArray = this.state.FailedCards;
    if(i + this.state.DisplayIndex < cardArray.length && this.state.DisplayIndex + i >= 0)
    {
      this.setState((prevState) => ({'DisplayIndex' : prevState.DisplayIndex + i}));
    }
    
  }




  checked(bool)
  {
    if(this.state.StudyMode == "hold")
    {

      if(bool)
      {
        this.restart();
      }
      else{
        this.props.history.push("/viewset/" + this.setID);
      }
    }
    else{

      

      if(bool)
      {

        
        var CorrectCards = this.state.CorrectCards;
        var FailedCards = this.state.FailedCards;
        var card =  FailedCards.splice(this.state.DisplayIndex , 1);
        card.CorrectCount += 1;
        CorrectCards.push(card[0]);
        

        

        if(FailedCards.length == 0)
        {
          Api.updateAccuracy({"cards" : this.state.CorrectCards , "set_id" : this.setID} , () => {return})
          
          this.setState((prevState) => ({"FailedCards" : [{"term" : "You've finished the set, would you like to review it again?" , "definition" : "You've finished the set, would you like to review it again?" }] , "Count" : prevState.Cards.length.toString() + " / " + prevState.Cards.length.toString() ,  "StudyMode" : "hold" , "StudyIconClass" : "" , "ShowTable" : false , "Progress" : 100 }))
        }
        else{
          this.setState((prevState) => ({"FailedCards": FailedCards , "CorrectCards" : CorrectCards , "DisplayIndex" : prevState.DisplayIndex  , "Count" : (prevState.CorrectCards.length).toString() + " / " + prevState.Cards.length.toString() ,  "Progress" : Math.floor(100 * CorrectCards.length / (FailedCards.length + CorrectCards.length) ) }));

        }
      



      }
      else{
        var FailedCards = this.state.FailedCards;
        var card = FailedCards.splice(this.state.DisplayIndex , 1);

        card[0].MissedCount += 1;
  
        FailedCards.push(card[0])
        this.setState({"FailedCards": FailedCards });
        


      }
    }
  }


  openDeletePopup(){
    this.setState({"DeletePopup" : true});
  }
  closeDeletePopup(){
    this.setState({"DeletePopup" :false});
  }

  skip(){
    var FailedCards = this.state.FailedCards;
    var card = FailedCards.splice(this.state.DisplayIndex , 1);
    FailedCards.push(card[0])
    this.setState({"FailedCards": FailedCards });
    
  }
  switchTermDef(){
    
    this.setState((prevState) => ({"Display" : prevState.Display == "Term" ? "Definition" : "Term"}) )
  }
  restart()
  {
    this.setState((prevState) => ({"StudyMode" : "" , "FailedCards" : prevState.Cards.slice(), "CorrectCards" : [],  "Progress" : 0 , Count : "0 / " +  prevState.Cards.length.toString() }))
  }

  render(){
    
    if(this.state.Loading)
    {
      return(
        <div className='view-wrapper'>
          <TitleBar ></TitleBar>
          <h3>Loading your set please wait</h3>
          <Footer></Footer>
        </div>
        
      );
    }
    else
    {
      return(
        
        <div className='view-wrapper'>
          
          <TitleBar selected="My Sets" ></TitleBar>
          <div className="card-grid">
            <div className="side-bar-wrapper">
            <ViewSideBar SetName={this.state.SetName} SetID={this.SetID} openDeletePopup={this.openDeletePopup.bind(this)} setID ={this.setID} Mode="Flip" Progress={this.state.Progress} Count={this.state.Count} Display={this.state.Display}></ViewSideBar>
          </div>
          <div className="set-top-bar-wrapper">
            <div className="flip-switch-wrapper" data-tip data-for="flipTip">
                <MdFlipToFront className={"hoverable flip-switch-button"}  onClick={(e) => this.switchTermDef()}></MdFlipToFront>
            </div>
            <div className="flip-reset-wrapper" data-tip data-for="resetTip">
            <FaUndo className={"hoverable flip-reset-button"}  onClick={(e) => this.restart()}></FaUndo>  
            </div>   

            <ReactTooltip id="flipTip" place="top"  effect="solid" wrapper="span">Flip whether term or definition is displayed first</ReactTooltip>
            <ReactTooltip id="resetTip" place="top"  effect="solid" wrapper="span">Restart</ReactTooltip>   
    

          </div>

           
            <div className="cards">

            <div className="hoverable card">
                <div className="card__side card__side--front card__side--front-1">
                    <div className="card__description" style={{"font-size" : this.state.Display == "Term" ? ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].term) : ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].definition)}}>{this.state.Display == "Term" ? this.state.FailedCards[0].term : this.state.FailedCards[0].definition }</div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                    <div className="card__description" >{this.state.Display == "Term" ? this.state.FailedCards[0].definition : this.state.FailedCards[0].term}</div>

                </div>

            </div>

            <div className="eval-div">

              <div className="hoverable flip-button" style={{"backgroundColor" : 'red'}} onClick={() => this.checked(false)}>{this.state.StudyMode == "hold" ? "No" : "Incorrect"}</div>
              {this.state.StudyMode == "hold" ? <></> : <div className="hoverable flip-button" style={{"backgroundColor" : '#FCAB10'}} onClick={() => this.skip()}>Skip</div>}   
              <div className="hoverable flip-button" style={{"backgroundColor" : '#20c294'}} onClick={() => this.checked(true)}>{this.state.StudyMode == "hold" ? "Yes" : "Correct"}</div>              
    
                  
            
            </div>
    


          </div>
       </div> 


       <DeletePopup DeletePopup={this.state.DeletePopup} closeDeletePopup={() => this.closeDeletePopup()} SetID={this.setID}></DeletePopup>

        </div>
  
      );
    }
  }
    
  


};

export default FlipStudy;

/* <table style={{  borderSpacing: "0 15px"}}>
<tbody>
  <tr><td className="divtab">{this.SetName}</td></tr>

  {this.state.cards.map((card , i) => (
      <Card term={card.Term} definition={card.Definition} key={i} index={i} />
    ))}
  
    </tbody>
</table> */