
// import SignIn from "../pages/SignIn";
// import SignUp from "../pages/SignUp";
import React from "react";
import {Component} from "react";
import ReactDOM from "react-dom";
import { API_URL } from "./env";

import  LoginWrapper  from "./Login/LoginWrapper";

import LandingPage from "./Landing-Pages/LandingPage"

import  CreateSet  from "./Dashboard/Create_Set/CreateSet";
import  AutomaticGenerator  from "./Dashboard/Automatic_Generator/AutomaticGenerator";
import ViewSet from "./Dashboard/View_Set/ViewSet";
import FlipStudy from "./Dashboard/View_Set/FlipStudy/FlipStudy"
import TypeStudy from "./Dashboard/View_Set/TypeStudy/TypeStudy"
import QuizStudy from "./Dashboard/View_Set/QuizStudy/QuizStudy"

import  ProtectedRoute  from "./Auth/protected_route";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Dashboard } from "./Dashboard/dashboard";
// import { Powerpoint } from "./Dashboard/Powerpoint";
import ForgotPassword from "./Login/ForgotPassword"
import ResetPassword from "./Login/ResetPassword"
import Unsubscribe from "./Login/Unsubscribe"

import PrivacyPolicy from './Header-Footer/Privacy'
import TermsAndConditions from './Header-Footer/TermsAndConditions'

import AboutPage from './Landing-Pages/About'
import Feedback from './Landing-Pages/Feedback'
import withTracker from './withAnalytics'
import {prod} from './env.js'
import reducer from './Auth/reducer'
import {Provider} from 'react-redux'

import "./index.css"
const axios = require('axios');

// import { Provider, connect } from 'react-redux';
require('dotenv').config();

class App extends Component {
  constructor(props){
    super(props);
  }
  componentWillMount(){
    // axios.post(API_URL + '/login/senderror' ).then((resp) => {return}).catch((err) => console.log(err.response.status))
    axios.get(API_URL + '/login/checkauth' ).then((resp) =>
    {
      reducer.dispatch({type: "INITIALIZE" ,auth: resp.data.auth, version : Math.random() >.99 ? "b" : "b" , username : resp.data.username ? resp.data.username.firstname : "" , tutorial : resp.data.tutorial ? resp.data.tutorial : false})
    })
  

  }
  render( )
  {

    return (
      // <Provider store={store}>

      <div className="App">
        <Switch>
        <Route exact path="/" component={withTracker(LandingPage)} />
          <Route exact path="/login" component={withTracker(LoginWrapper)} />
          <Route exact path="/about" component={withTracker(AboutPage)} />
          <Route exact path="/termsandconditions"  component={withTracker(TermsAndConditions)}/>
          <Route exact path="/privacy"  component={withTracker(PrivacyPolicy)}  />
          <Route exact path="/forgotpassword"  component={withTracker(ForgotPassword)}  />
          <Route exact path="/resetpassword/:id"  component={withTracker(ResetPassword)}  />
          <Route exact path="/unsubscribe"  component={withTracker(Unsubscribe)}  />
          <ProtectedRoute exact path="/feedback" component={Feedback} />
          <ProtectedRoute exact path="/app" component={withTracker(Dashboard)} />
        {/*<ProtectedRoute exact path="/powerpoint" component={withTracker(Powerpoint)} /> */}
          <ProtectedRoute exact path="/viewset/:id" component={withTracker(ViewSet)} />
          <ProtectedRoute exact path="/typestudy/:id" component={withTracker(TypeStudy)} />
          <ProtectedRoute exact path="/quizstudy/:id" component={withTracker(QuizStudy)} />
          <ProtectedRoute exact path="/flipstudy/:id" component={withTracker(FlipStudy)} />
          <ProtectedRoute exact path="/createset/:id" component={withTracker(CreateSet)} />
          <ProtectedRoute exact path="/automatic" component={withTracker(AutomaticGenerator)} />
          
          {/* <button onClick={() => {this.props.history.push("/signup");}}>Create an Account</button> */}
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </div>
      // </Provider>
    );
  


}
  }
  



const rootElement = document.getElementById("root");



ReactDOM.render(
  <Provider store={reducer}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>,
  rootElement
);
