import React from "react";
import auth from "../Auth/auth";
import logo from "../images/img_techucateNoWhite.png"; 
import SetSelection from "./View_Set/SetSelection/SetSelection"
import TitleBar from "../Header-Footer/TitleBar"




export const Dashboard = props => {
  return (


    <div>
    <TitleBar selected="/app"></TitleBar>
    <SetSelection ></SetSelection>
    </div>
  );



}

