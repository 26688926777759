import React, { Component } from 'react';
import Auth from "../Auth/auth";
import axios from 'axios';
import {GoogleLogin} from 'react-google-login'
import logo from "../images/img_techucateNoWhite.png"; 
import "./landing_page.css";
import "../index.css";
import TitleBar from '../Header-Footer/TitleBar';
import { API_URL , REACT_APP_GOOGLE_CLIENT_ID } from '../env';
import Footer from '../Header-Footer/Footer';
import { NavLink } from 'react-router-dom';
import Api from '../services/api.js'
import {connect} from "react-redux"
import api from '../services/api';
import  Login  from "./Login";
import {refreshTokenSetup} from '../Utils/refreshToken'

axios.defaults.withCredentials = true;
// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class LoginWrapper extends Component {
  constructor(props){
    super(props);
    this.state = { 
        "mobile": false,
        "firstName": '',
        "lastName": '',
        "email": '',
        "password": '',

      
      "activePanel": props.activePanel,
      "UsernameError" : {
        "Text" : "",
        "Status" : false
      },
      "LoginError" : ""

    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentDidMount()
  {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    var data = {
      "action" : "load login " + (this.state.mobile ? " mobile" : " desktop"),
      "version" : "" ,
       "page" : "landing"
    }
    api.logEvent(data , () => {return})
  }

  updateWindowDimensions() {
    const width = window.innerWidth;
    var popupWidth = "400px";
    if(width < 600)
    {
      this.setState({ "mobile" : true });
    }
    else{
      this.setState({ "mobile" : false });
    }
    
    
  }




render(){

  return (
    <div className='view-wrapper landing-wrapper'>

<TitleBar selected="login"></TitleBar>



<div className="login-body"> 
<Login></Login>

</div>
 
 
<Footer></Footer>   
  </div>);
}



} 
export default (LoginWrapper);















