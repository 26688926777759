import React, { Component } from 'react';
import "../SetSelection/SetList.scss"
import "./TypeStudy.scss"
// import ColorPicker from 'material-ui-color-picker'



import {IoIosColorPalette , IoIosFitness , IoIosFlash , IoIosFlask , IoIosGitNetwork , IoIosJet , IoIosMedical , IoIosNuclear , IoIosPaper , IoIosPerson , IoIosPlanet , IoIosRainy , IoIosPulse , IoIosRestaurant , IoIosRocket , IoIosSchool 
    , IoIosStats , IoIosTrendingUp , IoMdBasketball, IoMdContacts  } from 'react-icons/io'
import {FaPencilAlt, FaRegBuilding  } from 'react-icons/fa'

import Carousel, {  slidesToShowPlugin  }  from '@brainhubeu/react-carousel';
import TextareaAutosize from 'react-autosize-textarea';
// import ContentEditable from 'react-contenteditable'
import ContentEditable from "react-contenteditable"





class CheckedInput extends Component {
    constructor(props){
        super(props);
    
        // this.contentEditable = React.createRef();
        this.state = { "Checking" : props.check  , "TypedVal" : "" ,  "Untyped": props.check  , "HTML" : ""  };
        this.handleChange = this.handleChange.bind(this);
        this.submit = props.submit;

        }
        componentDidUpdate(prevProps) {
            if(this.props.check !== prevProps.check  ) 
            {
              this.setState({"Checking" : this.props.check , "Untyped": this.props.check ,  "HTML" : "" ,  "TypedVal" : ""    })
            }
          }  

        rebuildHTML(text)
        {
            var ret = "";
            for (var index = 0; index < text.length; index++) { 
                ret += "<span class=\"untyped\"" + text[index] +"</span>"
            }
        }
        componentDidUpdate(prevProps) {
            if(this.props.check !== prevProps.check) 
            {
              this.setState({"Checking" : this.props.check , "Untyped": this.props.check})
            }
          }
        handleChange(event) {
            var val = event.target.value.replace(/<span[^>]*>/g, '');
            
            val = val.replace(/<\/font>/g, '');
            val = val.replace(/<font[^>]*>/g, '');
            val = val.replace(/<\/span>/g, '');
            val = val.replace(/&nbsp;/g, ' ');

            val = val.replace(/&[^;]*;/g, '');
           
            if(val == "<br>")
            {
                val ="";
            }


            var ret = "";
            var untyped = "";
            const text = this.state.Checking;

            for (var index = 0; index < text.length; index++) { 
                if(index < val.length)
                {
                    const repVal = val[index] == ' ' ? "&nbsp;" : val[index];
                    if(val[index] === text[index])
                    {
                        ret += "<span class=\"correct copy-font\">" + repVal +"</span>"
                    }
                    else{
                        ret += "<span class=\"incorrect copy-font\">" + repVal +"</span>"
                    }
                    
                }
                else{
                    untyped += text[index] 
                }
                
            }
     
            this.setState({"HTML" : ret , "Untyped" : untyped , "Value" : val});


            
            
          }
 

    render(){


        return(        
                <div className="type-study-input">
                    <div className="check-card-body">
                    <div className="editable-wrapper">
                    <div className="editable-section">
                    <div onClick={() =>  document.getElementById("contentEdit").focus() }>
                        <ContentEditable 
                        html={this.state.HTML} // innerHTML of the editable div
                        onChange={this.handleChange} // handle innerHTML change
                        id={"contentEdit"}
                        className={"study-content-editable"}
                        />
                        
                        <span className="untyped copy-font" >{this.state.Untyped}</span>
                        </div>
                            </div>
                                </div> 
                        </div>
                    <div className="hoverable type-button" style={{"backgroundColor" : '#20c294'}} onClick={(e) => this.submit(this.state.Untyped)}>{"Submit"}</div>
                </div>                

        )

    }      
    



    
  };
  
  export default CheckedInput;