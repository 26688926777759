import React, { useState } from 'react';
import {MdKeyboardArrowDown , MdKeyboardArrowRight , MdBook , MdCheck , MdClose} from 'react-icons/md'
import {Collapse} from 'react-collapse';
import './CardTable.scss'
export function CardTable(props) {

    const [ShowTable , setShowTable] = useState(true);


    return(<ul className="card-table" >

              <li className="card-table-header">
                <div className="col col-term">Term</div>
                <div className="col col-def">Definition</div>
      <div className="hoverable hideIcon" onClick={() => setShowTable(!ShowTable)}>{ShowTable ?  <MdKeyboardArrowDown></MdKeyboardArrowDown> : <MdKeyboardArrowRight></MdKeyboardArrowRight>}  </div>

                
              </li>
         
              

              <Collapse className="card-table-body" isOpened={ShowTable}>
          

              
             
                    {props.Cards.map((card , i) => (
                      <li className="card-table-row" key={"card-" + i}>
                        <div className="col col-term">{card.term}</div>
                        <div className="col col-def">{card.definition}</div> 
                </li>
                  ))}
              

      


            
            </Collapse>
            </ul>  )
}