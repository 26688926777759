import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {BsTrash , BsFillPlusCircleFill} from 'react-icons/bs'
import { IconContext } from "react-icons";
import {connect} from "react-redux"
import "./SetList.scss"
// import ColorPicker from 'material-ui-color-picker'

import { DragDropContext, Draggable,Droppable } from 'react-beautiful-dnd';




class SetList extends Component {
    constructor(props){
        super(props);
        this.key = this.props.key;
        
        this.count = props.small ? 6 : 9;

        this.state = { "Sets" : this.props.Sets.filter(set=>set.Topic == this.props.TopicName) ,  "TopicName" : this.props.TopicName };
        }
        

    componentDidUpdate(prevProps) {
        var filtered =this.props.Sets.filter(set=>set.Topic == this.props.TopicName)
        if(this.state.TopicName !== this.props.TopicName || this.state.Sets.length != filtered.length ) 
        {
          this.setState({"TopicName" : this.props.TopicName , "Sets" : filtered })
        }
      }


    render(){    
        return(
        <Droppable droppableId={"cards-" + this.state.TopicName} isDropDisabled={this.state.TopicName == "Recommended"}>
        {(provided , snapshot) => (   

             <div  {...provided.droppableProps} ref={provided.innerRef}   style={{backgroundColor: snapshot.isDraggingOver ? "gainsboro": "transparent"  }}   >
                             {provided.placeholder}  
                 <div className="set-list">
                     {this.state.Sets.map((set , key) => (
                         <Draggable key={"card-" + set.SetID} draggableId={"card-" + set.SetID } index={key} isDragDisabled={this.state.TopicName == "Recommended" || this.state.TopicName == ""}>
                             
                
                             {(provided) => (  
                                 <NavLink to={"viewset/" + set.SetID} className="set"  key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                              
                                     <div className="set-texts">

                                     <div className="set-name">
                                         {set.SetName}
                                         </div>
                                         <div className="set-description">
                                         {set.SetCount != 1 ? set.SetCount + " TERMS" :  set.SetCount + " TERM" } <br></br>
                                         {set.DueDate != null ? "DUE " + set.DueDate.split("T")[0]  : ""}
                                         </div>
                                     </div>
                                
                                 </NavLink>
                             )}
                             
                         </Draggable>
                     ))}
                     <div className="topic-set-plus">
                        <IconContext.Provider value={{ className: "hoverable plus" }}>
                            <NavLink to={"/createset/none"}><BsFillPlusCircleFill > </BsFillPlusCircleFill></NavLink>
                        </IconContext.Provider>
                     </div>
                     
                 </div>
             
             </div> 
         )}
         </Droppable >
        )

    }      
    



    
  };
  
  const mapStatetoProps = state => {
    return{
      Sets : state.Sets
    }
  }

  const mapDispatchtoProps = dispatch => {
    return{


  
  }
  } 
  


  
  
  
  
  
  
  
  
  
  export default connect(mapStatetoProps , mapDispatchtoProps)(SetList);
