import React, { Component } from '../../../../node_modules/react';

import axios from 'axios'

import TitleBar from '../../../Header-Footer/TitleBar';
import {API_URL} from '../../../env'


import auth from '../../../Auth/auth'
import { CircularProgressbar } from 'react-circular-progressbar';
import { IconContext } from "react-icons";
import ProgressBar from 'react-bootstrap/ProgressBar'
import {MdBook , MdCheck , MdClose} from 'react-icons/md'
import {CardTable} from '../CardTable'
import { NavLink } from 'react-router-dom';
import {FiBookOpen} from 'react-icons/fi'
import {FaEclispe} from 'react-icons/fa'
import {MdDelete } from 'react-icons/md'

import Footer from '../../../Header-Footer/Footer';
import Modal from 'react-awesome-modal';
import ViewSideBar from '../ViewSideBar'
import { Progress } from 'semantic-ui-react'
import Api from '../../../services/api'
import './QuizStudy.scss'
import {FaUndo} from 'react-icons/fa'
import {MdFlipToFront} from 'react-icons/md'
import ReactTooltip from 'react-tooltip'
import DeletePopup from '../DeletePopup'
import ViewSetHelper from '../ViewSetHelper'

import reducer from "../../../Auth/reducer"


const delay = ms => new Promise(res => setTimeout(res, ms));
axios.defaults.withCredentials = true;

class QuizStudy extends Component {

  constructor(props)
  {
    super(props);
  
    if(props.match == undefined)
    {
      this.setID =""
    }
    else{
      this.setID = props.match.params.id;
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.state = {"DeletePopup": false , 'SetName': '' , 'Loading' : true , 'Cards' : [] ,  "Count" : "" , "Display" : "Definition" , 'FailedCards' : [{"term" : "" , "definition" : "" }] ,
     'CorrectCards' : [] , 'DisplayIndex' : 0 , 'StudyMode' : '' , "Progress" : 0 ,  "popupWidth" : "400px" , "Mode" : "Term" ,
     'Options' : []
     };
    


  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // make api call to get the cards
     Api.getCards(this.setID, async (cardArray, setName) => {

      for(var i= 0 ; i < cardArray.length ; i++)
      {
        cardArray[i].MissedCount = 0;
      }
      var options = this.getOptions(cardArray.slice() , cardArray.slice());
      this.setState({ "Options" : options.Options , "Correct" : options.Correct , 'Loading' : false , 'Cards' : cardArray.slice() , 'FailedCards' : cardArray.slice() , 'SetName' : setName , "Count" : "0 / " + cardArray.length.toString()} );  
      
    });

    
  }
 

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }


  updateWindowDimensions() {
    const width = window.innerWidth;
    var popupWidth = "400px";
    if(width < 460)
    {
        popupWidth = "300px";
    }
    
    this.setState({ "popupWidth" : popupWidth });
  }

  changeCard(i)
  {
    const cardArray = this.state.FailedCards;
    if(i + this.state.DisplayIndex < cardArray.length && this.state.DisplayIndex + i >= 0)
    {
      this.setState((prevState) => ({'DisplayIndex' : prevState.DisplayIndex + i}));
    }
    
  }


  async checked(i)
  {
    if(this.state.StudyMode == "hold")
    {

      if(i == this.state.Correct)
      {
        this.restart();
      }
      else{
        this.props.history.push("/viewset/" + this.setID);
      }
    }
    else{

      

      if(i == this.state.Correct)
      {


        var CorrectCards = this.state.CorrectCards;
        var FailedCards = this.state.FailedCards;
        var card =  FailedCards.splice(this.state.DisplayIndex , 1);
        card.CorrectCount += 1;
        CorrectCards.push(card[0]);

        var tempOptions = this.state.Options;


        if(FailedCards.length == 0)
        {

          Api.updateAccuracy({"cards" : this.state.CorrectCards , "set_id" : this.setID} , () => {return})
          
          this.setState((prevState) => ({"FailedCards" : [{"term" : "You've finished the set, would you like to review it again?" , "definition" : "You've finished the set, would you like to review it again?" }] ,
           "DisplayIndex" : 0 , "Count" : prevState.Cards.length.toString() + " / " + prevState.Cards.length.toString() ,"Correct" : 1,
            "StudyMode" : "hold" , "StudyIconClass" : "" , "ShowTable" : false , "Progress" : 100 , "Options" : [{"text" : "No" , "class" : "quiz-incorrect"} , {"text" : "Yes" , "class" : "quiz-correct"}] }))
        }
        else{
          tempOptions[i].class = "quiz-correct"

          this.setState({"Options": tempOptions})
  
          await delay(500);

          var options = this.getOptions();
          this.setState((prevState) => ({"Options" : options.Options , "Correct" : options.Correct , "FailedCards": FailedCards , "CorrectCards" : CorrectCards , "DisplayIndex" : prevState.DisplayIndex  , "Count" : (prevState.CorrectCards.length).toString() + " / " + prevState.Cards.length.toString() ,  "Progress" : Math.floor(100 * CorrectCards.length / (FailedCards.length + CorrectCards.length) ) }));
          
        }
      



      }
      else{

        var FailedCards = this.state.FailedCards;
        FailedCards[this.state.DisplayIndex].MissedCount += 1;;


        var tempOptions = this.state.Options;
        tempOptions[i].class = "quiz-incorrect"

        this.setState({"Options" : tempOptions , "FailedCards": FailedCards})
  



      }
    }
  }


  openDeletePopup(){
    this.setState({"DeletePopup" : true});
  }
  closeDeletePopup(){
    this.setState({"DeletePopup" :false});
  }

  switchTermDef(){
    var options = this.getOptions(this.state.Cards , this.state.FailedCards, this.state.Display == "Term" ? "Definition" : "Term")
    this.setState((prevState) => ({"Options": options.Options , "Correct" : options.Correct , "Display" : prevState.Display == "Term" ? "Definition" : "Term"}) )
   
  }
  restart()
  {
    var options = this.getOptions(this.state.Cards.slice() , this.state.Cards.slice())
    this.setState((prevState) => ({"Options": options.Options , "Correct" : options.Correct , "StudyMode" : "" , "FailedCards" : prevState.Cards.slice(), "CorrectCards" : [],  "Progress" : 0 , Count : "0 / " +  prevState.Cards.length.toString() }))
  }
  


  getOptions(Cards=this.state.Cards , FailedCards=this.state.FailedCards , Display=this.state.Display)
  {
    
    const correctAnswer = Display == "Term" ? FailedCards[this.state.DisplayIndex].definition : FailedCards[this.state.DisplayIndex].term;




    const peak = Cards.length < 4 ? Cards.length  : 4
    const start = Math.floor(Math.random() * peak);
    var options = ["" * peak];
    var optionsDict = [{} * peak];
    options[start] = correctAnswer;
    optionsDict[start] = {"text" : correctAnswer , "class" : ""};

    for(var i = 1 ; i < peak ; i++)
    {
      var randIdx = Math.floor(Math.random() * Cards.length)
      var wrongAnswer = Display == "Term" ? Cards[randIdx].definition: Cards[randIdx].term;
      while(options.includes(wrongAnswer))
       {
         randIdx = Math.floor(Math.random() * Cards.length)

        wrongAnswer = Display == "Term" ? Cards[randIdx].definition : Cards[randIdx].term ;


      }
      options[(start + i) % peak] = wrongAnswer ;
      optionsDict[(start + i) % peak] = {"text" : wrongAnswer , "class" : ""};
    }


    return({"Options" : optionsDict , "Correct" : start})

  }
  render(){
    
    if(this.state.Loading)
    {
      return(
        <div className='view-wrapper'>
          <TitleBar ></TitleBar>
          <h3>Loading your set please wait</h3>
          <Footer></Footer>
        </div>
        
      );
    }
    else
    {
      return(
        
        <div className='view-wrapper'>
          
          <TitleBar selected="My Sets" ></TitleBar>
          <div className="card-grid">
            <div className="side-bar-wrapper">
            <ViewSideBar SetName={this.state.SetName} SetID={this.SetID} openDeletePopup={this.openDeletePopup.bind(this)} setID ={this.setID} Mode="Quiz" Progress={this.state.Progress} Count={this.state.Count} Display={this.state.Display}></ViewSideBar>
          </div>
          <div className="set-top-bar-wrapper">
            <div className="flip-switch-wrapper" data-tip data-for="flipTip">
                <MdFlipToFront className={"hoverable flip-switch-button"}  onClick={(e) => this.switchTermDef()}></MdFlipToFront>
            </div>
            <div className="flip-reset-wrapper" data-tip data-for="resetTip">
            <FaUndo className={"hoverable flip-reset-button"}  onClick={(e) => this.restart()}></FaUndo>  
            </div>   

            <ReactTooltip id="flipTip" place="top"  effect="solid" wrapper="span">Flip whether term or definition is displayed first</ReactTooltip>
            <ReactTooltip id="resetTip" place="top"  effect="solid" wrapper="span">Restart</ReactTooltip>   
    

          </div>

           
            <div className="cards">

            <div className="hoverable card">
              {console.log(this.state)}
                <div className="card__side card__side--front card__side--front-1 disable-rotate">
                    <div className="card__description" style={{"fontSize" : this.state.Display == "Term" ? 
                    ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].term) : ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].definition)}}>{this.state.Display == "Term" ?
                     this.state.FailedCards[this.state.DisplayIndex].term : this.state.FailedCards[0].definition }</div>
                </div>

            </div>

            <div className="quiz-div">
            {this.state.Options.map((option , i) => (
              <div key={i} className={option.class + " quiz-option"} style={{"fontSize" : ViewSetHelper.getOptionSize(option.text)} } onClick={()=> this.checked(i)}>{option.text}</div>
            ))}
 
              {/* <div className="hoverable flip-button" style={{"backgroundColor" : 'red'}} onClick={() => this.checked(false)}>{this.state.StudyMode == "hold" ? "No" : "Incorrect"}</div>
              {this.state.StudyMode == "hold" ? <></> : <div className="hoverable flip-button" style={{"backgroundColor" : '#FCAB10'}} onClick={() => this.skip()}>Skip</div>}   
              <div className="hoverable flip-button" style={{"backgroundColor" : '#20c294'}} onClick={() => this.checked(true)}>{this.state.StudyMode == "hold" ? "Yes" : "Correct"}</div>               */}
    
                  
            
            </div>
    


          </div>
       </div> 

       <DeletePopup DeletePopup={this.state.DeletePopup} closeDeletePopup={() => this.closeDeletePopup()} SetID={this.setID}></DeletePopup>

        </div>
  
      );
    }
  }
    
  


};

export default QuizStudy;

/* <table style={{  borderSpacing: "0 15px"}}>
<tbody>
  <tr><td className="divtab">{this.SetName}</td></tr>

  {this.state.cards.map((card , i) => (
      <Card term={card.Term} definition={card.Definition} key={i} index={i} />
    ))}
  
    </tbody>
</table> */