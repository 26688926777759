import React, { Component } from 'react';
import auth from "../../../Auth/auth";
import Api from "../../../services/api"
import Set from '../../Set/Set'
import {NavLink } from 'react-router-dom';
import {API_URL} from '../../../env';
import axios from 'axios';
import {CirclePicker} from 'react-color';
import Select from "react-select";
import { withRouter } from 'react-router-dom';
import "./EditTopic.scss";


import {IoIosColorPalette , IoIosFitness , IoIosFlash , IoIosFlask , IoIosGitNetwork , IoIosJet , IoIosMedical , IoIosNuclear , IoIosPaper , IoIosPerson , IoIosPlanet , IoIosRainy , IoIosPulse , IoIosRestaurant , IoIosRocket , IoIosSchool 
    , IoIosStats , IoIosTrendingUp , IoMdBasketball, IoMdContacts  } from 'react-icons/io'
import {FaPencilAlt  } from 'react-icons/fa'
import api from '../../../services/api';

const selectOptions = [
    {name: "TopicIcon" , value: "" , label: <div className="select-div">None</div>},
    {name: "TopicIcon" , value: "IoIosColorPalette" , label: <div className="select-div">Palette <div className="select-icon"><IoIosColorPalette></IoIosColorPalette></div></div>},
    {name: "TopicIcon" , value: "IoIosFitness" , label: <div className="select-div">Fitness <div className="select-icon"><IoIosFitness></IoIosFitness></div></div>},
    {name: "TopicIcon" , value: "IoIosFlash" , label: <div className="select-div">Flash <div className="select-icon"><IoIosFlash></IoIosFlash></div></div>},
    {name: "TopicIcon" ,value: "IoIosFlask" , label: <div className="select-div">Beaker <div className="select-icon"><IoIosFlask></IoIosFlask></div></div>},
    {name: "TopicIcon" , value: "IoIosGitNetwork" , label: <div className="select-div">Network <div className="select-icon"><IoIosGitNetwork></IoIosGitNetwork></div></div>},
    {name: "TopicIcon" , value: "IoIosJet" , label: <div className="select-div">Jet <div className="select-icon"><IoIosJet></IoIosJet></div></div>},
    {name: "TopicIcon" , value: "IoIosMedical" , label: <div className="select-div">Medical <div className="select-icon"><IoIosMedical></IoIosMedical></div></div>},
    {name: "TopicIcon" , value: "IoIosNuclear" , label: <div className="select-div">Nuclear <div className="select-icon"><IoIosNuclear></IoIosNuclear></div></div>},
    {name: "TopicIcon" , value: "IoIosPerson" , label: <div className="select-div">Person <div className="select-icon"><IoIosPerson></IoIosPerson></div></div>},
    {name: "TopicIcon" , value: "IoIosPlanet" , label: <div className="select-div">Planet <div className="select-icon"><IoIosPlanet></IoIosPlanet></div></div>},
    {name: "TopicIcon" , value: "IoIosRainy" , label: <div className="select-div">Rainy <div className="select-icon"><IoIosRainy></IoIosRainy></div></div>},
    {name: "TopicIcon" , value: "IoIosRestaurant" , label: <div className="select-div">Food <div className="select-icon"><IoIosRestaurant></IoIosRestaurant></div></div>},
    {name: "TopicIcon" , value: "IoIosRocket" , label: <div className="select-div">Rocket <div className="select-icon"><IoIosRocket></IoIosRocket></div></div>},
    {name: "TopicIcon" , value: "IoIosSchool" , label: <div className="select-div">School <div className="select-icon"><IoIosSchool></IoIosSchool></div></div>},
    {name: "TopicIcon" , value: "IoIosStats" , label: <div className="select-div">Chart <div className="select-icon"><IoIosStats></IoIosStats></div></div>},
    {name: "TopicIcon" , value: "IoIosTrendingUp" , label: <div className="select-div">Market <div className="select-icon"><IoIosTrendingUp></IoIosTrendingUp></div></div>},
    {name: "TopicIcon" , value: "IoMdBasketball" , label: <div className="select-div">Sports <div className="select-icon"><IoMdBasketball></IoMdBasketball></div></div>},
    {name: "TopicIcon" , value: "IoMdContacts" , label: <div className="select-div">People <div className="select-icon"><IoMdContacts></IoMdContacts></div></div>}
    

]


class EditTopic extends Component {
    constructor(props){
        super(props);
        this.closeTopic = props.closeTopic;
        if(this.props.name === "")
        {
            this.state = { "TopicIcon" : "" , "TopicColor" : "rgba(0 , 0 , 0, .5)" , "TopicName" : "" , "circleSize" : this.props.circleSize , "PrevTopic" : "" };
        }
        else{
            this.state = { "TopicNameError"  : "" , "TopicIcon" : this.props.icon , "TopicColor" : this.props.color , "TopicName" :  this.props.name , "circleSize" : this.props.circleSize , "PrevTopic" : this.props.name  };
        }

    }
          
    componentDidUpdate(prevProps) {
     
        if(this.props.circleSize !== prevProps.circleSize || this.props.name !== prevProps.name || this.props.color !== prevProps.color || this.props.icon !== prevProps.icon)     {
            this.setState({  "circleSize" : this.props.circleSize , "TopicColor" : this.props.color , "TopicIcon" : this.props.icon , "TopicName" : this.props.name});
        }
      } 

    deleteTopic()
    {
 
            api.deleteTopic({ 'topic_name': this.state.TopicName ,  'prev_topic' : this.state.PrevTopic },
            (res) => {
                this.closeTopic();
            } , (err) => {
                this.setState({"TopicNameError" : err.response.data.Message})
            })
            
    }

    submitTopic()
    {   
        this.setState({"TopicNameError" : ""})
        if(this.state.TopicName === "" || this.state.TopicName.replace(/\s+/g, '') === "")
        {
            this.setState({"TopicNameError" : "Cannot Have Empty Topic Name"})
        }
        if(this.state.PrevTopic)
        {
            Api.updateTopic({ 'topic_name': this.state.TopicName ,  'topic_symbol': this.state.TopicIcon ,  'topic_color': this.state.TopicColor , 'prev_topic' : this.state.PrevTopic } ,
            (res) => {
                this.closeTopic();
                window.location.reload(false);
            } , (err) => {

                this.setState({"TopicNameError" : err.response.data.Message})
            })
        }
        else{
            
            Api.submitTopic({ 'topic_name': this.state.TopicName ,  'topic_symbol': this.state.TopicIcon ,  'topic_color': this.state.TopicColor   } ,
            (res) => {
                this.closeTopic();
                // window.location.reload(false);
            } , (err) => {
       
                this.setState({"TopicNameError" : err.response.data.Message})
            })
        }        
    }

    
    handleChange(event) {
        const name = event.name;
        const val = event.value;
        this.setState({ [name]: val });

      }

      handleColorChange(color)
      {
  
          if(color != undefined)
          {
              this.setState({"TopicColor" : color.hex.toString()});
          }
          
      }
  
    render()
    {
        return(
            <div className="add-topic">
                <div className="topic-input-wrapper">
                <div className="topic-create-text">Enter A Topic Name</div>
                {this.state.TopicNameError ? <div className="error">{this.state.TopicNameError}</div> : <></>}
                <input className="hoverable settopic-input input-spacing" type="text" name="TopicName" placeholder={this.state.TopicName}  onChange={(event) => this.handleChange({"name" : event.target.name , "value" : event.target.value})}/>
                <div className="topic-create-text">Select A Background Color</div>
                <CirclePicker circleSize={this.state.circleSize} colors={["#ed474a" , "#ef8354" ,"#B80c09" ,"#9D8Ad9" , "#70163c" , "#846075" , "#4a6c6f" , "#9a7aa0", "#8d94ba" ,"#2B50AA" , "#8093f1" , "#358600" , "#63c132", '#307351',  '#466365' , '#B49A67' , "#70798c" , "#3e885b" , "#938b4a1" ]} name='TopicColor' className='topic-color-picker' onChange={color => this.handleColorChange(color)}></CirclePicker>
  
                
            
                <div className="topic-select-wrapper">
                
                <Select options={selectOptions} name="TopicIcon" placeholder="Select An Icon" onChange={(event) => this.handleChange(event)}></Select>
                </div>
                </div>
                <div className='button-wrapper' >
                <button className="hoverable topic-button-plus set-list-buttons button-main" onClick={ () => this.submitTopic()}>Submit Topic</button>
                <button className="hoverable topic-button-plus set-list-buttons button-main" onClick={ () => this.deleteTopic()}>Delete Topic</button>
                </div>    
            </div>
            )
    }
}

export default withRouter(EditTopic);
