import axios from 'axios';
import { API_URL } from '../env';
import {connect} from "react-redux"
import {Component} from "react"
axios.defaults.withCredentials = true;

class Auth extends Component {
    constructor(props) {
      super(props)
      this.authenticated = false;
      this.sessionID = '';
      this.username = '';
      this.tutorial = true;
    }
    setSessionID(sessionID){
      this.sessionID = sessionID;
    }
  
    async login(state , cb , failcb) {

      
      } 
     
    
  
      logout() {
      this.authenticated = false;
      this.props.SetUsername("");
      this.props.Logout();
      const resp = axios.post(API_URL + '/login/logout' );
      
    }


  
    async setTutorial()
    {
      const resp = axios.post(API_URL + '/login/removetutorial' );
      this.SetReduceTutorial(false);
    }


  }
  

  
  const mapStatetoProps = state => {
    return{
    }
  }
  
  const mapDispatchtoProps = dispatch => {
    return{
      Authorize: () => {
        dispatch({ type: "AUTHORIZE"});
    },
    Logout: () => {
      dispatch({ type: "DEAUTHORIZE"});
    },
    SetReduceTutorial: (tut) => {
      dispatch({ type: "SETTUTORIAL" , tutorial : tut});
    },
    SetUsername: (user) => {
      dispatch({ type: "SETUSERNAME" , username : user});
    },
  
  }
  } 
  
  
  
  
  export default connect(mapStatetoProps , mapDispatchtoProps)(Auth);
  