class ViewSetHelper{
    getOptionSize(val){
        const width = window.innerWidth;
        var fonts = []
        if(width > 1000)
        {
          if(val.length >= 190){
            return "12pt";
          }
          else if(val.length >= 150){
            return "15pt"
          }
          else if(val.length >= 100){
            return "16pt"
          }
          else if(val.length >=50 ){
            return "18pt"
          }
          else if(val.length < 50 ){
            return "20pt"
          }
        }
        else if(width < 350){
          if(val.length >= 190){
            return "4pt";
          }
          else if(val.length >= 150){
            return "6pt"
          }
          else if(val.length >= 100){
            return "7pt"
          }
          else if(val.length >=50 ){
            return "10pt"
          }
          else if(val.length < 50 ){
            return "12pt"
          }
        }
        else if(width < 460){
          if(val.length >= 190){
            return "5pt";
          }
          else if(val.length >= 150){
            return "7pt"
          }
          else if(val.length >= 100){
            return "8pt"
          }
          else if(val.length >=50 ){
            return "11pt"
          }
          else if(val.length < 50 ){
            return "13pt"
          }
        }
        else if(width < 600){
          if(val.length >= 190){
            return "7pt";
          }
          else if(val.length >= 150){
            return "10pt"
          }
          else if(val.length >= 100){
            return "11pt"
          }
          else if(val.length >=50 ){
            return "12pt"
          }
          else if(val.length < 50 ){
            return "16pt"
          }
        }
        else if(width < 760)
        {
          if(val.length >= 190){
            return "8pt";
          }
          else if(val.length >= 150){
            return "11pt"
          }
          else if(val.length >= 100){
            return "12pt"
          }
          else if(val.length >=50 ){
            return "13pt"
          }
          else if(val.length < 50 ){
            return "16pt"
          }
        }
    
        else if(width < 1000)
        {
          if(val.length >= 190){
            return "10pt";
          }
          else if(val.length >= 150){
            return "13pt"
          }
          else if(val.length >= 100){
            return "15pt"
          }
          else if(val.length >=50 ){
            return "17pt"
          }
          else if(val.length < 50 ){
            return "20pt"
          }
        }
    
        return ""
          
}

    getMainFont(val){
        const width = window.innerWidth;
        var fonts = []
        if(width > 1000)
        {
          if(val.length >= 190){
            return "14pt";
          }
          else if(val.length >= 150){
            return "17pt"
          }
          else if(val.length >= 100){
            return "18pt"
          }
          else if(val.length >=50 ){
            return "20pt"
          }
          else if(val.length < 50 ){
            return "22pt"
          }
        }
        else if(width < 350){
          if(val.length >= 190){
            return "5pt";
          }
          else if(val.length >= 150){
            return "7pt"
          }
          else if(val.length >= 100){
            return "8pt"
          }
          else if(val.length >=50 ){
            return "11pt"
          }
          else if(val.length < 50 ){
            return "14pt"
          }
        }
        else if(width < 460){
          if(val.length >= 190){
            return "6pt";
          }
          else if(val.length >= 150){
            return "8pt"
          }
          else if(val.length >= 100){
            return "9pt"
          }
          else if(val.length >=50 ){
            return "12pt"
          }
          else if(val.length < 50 ){
            return "15pt"
          }
        }
        else if(width < 600){
          if(val.length >= 190){
            return "8pt";
          }
          else if(val.length >= 150){
            return "11pt"
          }
          else if(val.length >= 100){
            return "12pt"
          }
          else if(val.length >=50 ){
            return "14pt"
          }
          else if(val.length < 50 ){
            return "18pt"
          }
        }
        else if(width < 760)
        {
          if(val.length >= 190){
            return "9pt";
          }
          else if(val.length >= 150){
            return "12pt"
          }
          else if(val.length >= 100){
            return "14pt"
          }
          else if(val.length >=50 ){
            return "15pt"
          }
          else if(val.length < 50 ){
            return "18pt"
          }
        }
    
        else if(width < 1000)
        {
          if(val.length >= 190){
            return "12pt";
          }
          else if(val.length >= 150){
            return "15pt"
          }
          else if(val.length >= 100){
            return "17pt"
          }
          else if(val.length >=50 ){
            return "19pt"
          }
          else if(val.length < 50 ){
            return "22pt"
          }
        }
    
    
    
    
    
        return ""
          
}

}
export default new ViewSetHelper()