import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/flippso-logo-transparent.png'
import {MdCheck} from 'react-icons/md'
import { IconContext } from "react-icons";
import TitleBar from '../Header-Footer/TitleBar';
import { API_URL } from '../env';
import './landing-page.scss'
import './About.scss'
import Footer from '../Header-Footer/Footer';

axios.defaults.withCredentials = true;
// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class AboutPage extends Component {
  constructor(props){
    super(props);
    this.state = { 
      'feedback' : '',
      'submitted' : false


    };
    this.handleChange = this.handleChange.bind(this)
    this.submitFeedback = this.submitFeedback.bind(this)


  }


  
  handleChange(event) {        
    this.setState({ 'feedback': event.target.value });
}



submitFeedback() {

    axios.post(API_URL  + '/misc/submitfeedback', {'Feedback': this.state.feedback   })
      .then(function(res){

      } )
      .catch(err => {
 

      });
  
  this.setState({"submitted" : true});
}





render(){

  return (
    <div className='view-wrapper landing-wrapper'>

<TitleBar selected="About" ></TitleBar>
<div className="about-grid">

  <div className="about-grid-title"><img className="about-image" src={logo} ></img></div>
  
  
    <div className='about-grid-transparent'>About Us</div>
    <div className="about-grid-body">
    <div className='about-grid-text'>

    
At Flippso, we believe in efficient learning. Every student learns differently
and has different weaknesses, so we created a tool that caters to them to
maximize their learning. The focus of learning should be on the material
and not the "how". Flippso is the "how"!

      <br></br><br></br>Flippso helps you jump into studying quicker by uploading your notes and
turning them into a flashcard set automatically. We keep track of when you
study and how well you do, so we can recommend sets and cards. You can
now spend more time learning, earning better grades with the same
amount of study time.
<br></br><br></br>
Want to study the old-fashioned way? Head over to our "Create Set"
feature to manually type terms and definitions to custom tailor your
flashcards the way you want them. Label them under a specific topic to
keep your sets organized.
<br></br><br></br>
Lost? Head over to "My Topics" to see all of your study material. Still have
questions? Fill out our feedback survey below.
<br></br><br></br>
Lost? Head over to "My Topics" to see all of your study material. Still have
questions? Fill out our feedback survey below.
<br></br><br></br>
Thank you for your interest in Flippso flashcards. We are adding features to
the platform regularly. If you have feedback, features you would like to see,
or would like to learn more, please contact us at support@flippso.com
<br></br><br></br>
Happy flipping!


</div>
</div>
</div>
<div className="feedback-wrapper">
  <div className="feedback-title">Give us feedback!</div>
    <div className="feedback-header">We would love to hear feedback about your experience on our site. Please type below any issues you encountered or new
        features you'd like to see. Thank you for your input!</div>
    <input className="input-field input-field-feedback input-spacing" type="text" value={this.state.feedback} placeholder="I love using Flippso because..."  onChange={this.handleChange}></input>

    <div className="feedback-button-wrapper">
    <button className={"button-main feedback-submit " + (this.state.submitted ? "" : "feedback-bottom-spacer")} onClick={ () => this.submitFeedback() }>Submit Feedback</button>
    </div>
    {this.state.submitted ? 
    <div className="feedback-check-wrapper">
    <IconContext.Provider value={{ className: "hoverable check-icon" }}>
        <MdCheck></MdCheck>
    </IconContext.Provider>
    <span className="check-text">Recieved!</span>
    </div>
    : <></>}

    </div>
   

<Footer></Footer>    
  </div>);
}
}








export default AboutPage;





