import React, { Component } from 'react';
import Auth from "../Auth/auth";
import axios from 'axios';
import {GoogleLogin} from 'react-google-login'
import logo from "../images/img_techucateNoWhite.png"; 
import "./landing_page.css";
import "../index.css";
import TitleBar from '../Header-Footer/TitleBar';
import { API_URL , REACT_APP_GOOGLE_CLIENT_ID } from '../env';
import { withRouter } from 'react-router-dom';
import Footer from '../Header-Footer/Footer';
import { NavLink } from 'react-router-dom';
import Api from '../services/api.js'
import {connect} from "react-redux"
import api from '../services/api';
import {refreshTokenSetup} from '../Utils/refreshToken'

axios.defaults.withCredentials = true;
// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class Login extends Component {
  constructor(props){
    super(props);
    this.state = { 
        "mobile": false,
        "firstName": '',
        "lastName": '',
        "email": '',
        "password": '',

      
      "activePanel": props.activePanel,
      "UsernameError" : {
        "Text" : "",
        "Status" : false
      },
      "TermsError" : {
        "Text" : "",
        "Status" : false
      },
      "LoginError" : ""

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentDidMount()
  {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    var data = {
      "action" : "load login " + (this.state.mobile ? " mobile" : " desktop"),
      "version" : "" ,
       "page" : "landing"
    }
    api.logEvent(data , () => {return})
  }

  updateWindowDimensions() {
    const width = window.innerWidth;
    var popupWidth = "400px";
    if(width < 600)
    {
      this.setState({ "mobile" : true });
    }
    else{
      this.setState({ "mobile" : false });
    }
    
    
  }

  
  handleChange(event) {
    // event.preventDefault();

    if(event.target.name == "email")
    {
      this.setState({"UsernameError" : {"Text" : "" , "Satuts" : false} } );
    }
    else if(event.target.name == "checkbox")
    {
      this.setState({"TermsError" : {"Text" : "" , "Satuts" : false} } );
    }
    console.log(event.target.value)
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    
    if(this.state.activePanel == "")
    {
      this.setState({"LoginError" : ""});
      const data = {login: this.state.email , password: this.state.password};

      axios.post(API_URL + '/login/login', data ).then(res => {
        console.log(this.props.nextPage)
        if(res.data.sessionID !== ''){
          var data = {
            "action" : "login " + res.data.username,
            "version" : "" ,
             "page" : "login"
          }
          api.logEvent(data , () => {return})
          this.props.Authorize();
          this.props.SetReduceTutorial(res.data.tutorial);
          // this.props.SetUsername(res.data.username);
          this.props.history.push(this.props.nextPage);
          this.props.SetNextPage("/app");
        }

      }).catch(err => {console.log(err); this.setState({"LoginError" : "Incorrect username or password"})});

     
    }
    else{
      if(this.state.checkbox != "on")
      {
        this.setState({"TermsError" : {"Text" : "You must accept the terms of service" , "Status" : true}} );
        return;
      }
      const data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        marketing: true
  
      }
      
      Api.addUser(data , (res) => {
        this.changePanel();
      }, (err) => {
        this.setState({"UsernameError" : {"Text" : err.response.data.Message , "Status" : true}} 
      )}
      )


    }
  }
  handleGoogleLogin(googleData){
    api.googleLogin(googleData , (res)=> {   
      if(res.data.sessionID !== ''){

      var data = {
        "action" : "login " + res.data.username,
        "version" : "" ,
         "page" : "login"
      }
      api.logEvent(data , () => {return})
      this.props.Authorize();
      this.props.SetReduceTutorial(res.data.tutorial);
      // this.props.SetUsername(res.data.username);
      
      this.props.history.push(this.props.nextPage);
      this.props.SetNextPage("/app");
    }
    });

    refreshTokenSetup(googleData);

  }
  changePanel()
  {

    if(this.state.activePanel == "")
    {
      this.setState({activePanel: "right-panel-active" ,  "firstName": '', "lastName": '', "email": '', "password": ''})
    }
    else{
      this.setState({activePanel: "" ,  "firstName": '', "lastName": '', "email": '', "password": ''})
    }
  }


render(){

  return (
  <>




    <div className={"login-main-container " + this.state.activePanel} id="login-main-container">


          <div className="form-container sign-in-container">
            <form action="#">
              <div className="form-header sign-in">Sign In</div>

              <input className="login-input input-spacing" type="email" placeholder="Email" name="email" value={this.state.login} onChange={(e) => this.handleChange(e)} required/>
              <input className="login-input input-spacing" type="password" placeholder="Password" name="password" value={this.state.password} onChange={(e) => this.handleChange(e)} required/>
              {this.state.LoginError ? <div className="error">{this.state.LoginError}</div> : <></>}
               
              <NavLink to="/forgotpassword">Forgot your password?</NavLink>
              <button className="landing-button hoverable button-main sign-up-button" onClick={(e) => this.handleSubmit(e)}>Sign In</button>

              
              <GoogleLogin
               render={renderProps => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google-button hoverable"><div className="google-logo-wrapper"><div className="google-logo"></div></div><div className="google-button-text">Sign In With Google</div></button>
              )}
              clientId={REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Log in with Google"
              onSuccess={this.handleGoogleLogin}
              onFailure={this.handleGoogleLogin}
              cookiePolicy={'single_host_origin'}
          />
            </form>
          </div>
                    <div className="form-container sign-up-container">
            <form className="sign-up-form" action="#">
                <div className="form-header">Create Account</div>
                <div className="login-subtext">By signing up you agree that you are 13 years of age and older</div>
                <input  className="input-spacing create-input input" type="text"  placeholder="First Name" name="firstName" value={this.state.firstName} onChange={(e) => this.handleChange(e)} required />
                <input  className=" input-spacing create-input input" type="text"  placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={(e) => this.handleChange(e)} required/>
                <input   type="email" className={'input-spacing create-input input ' + (this.state.UsernameError.Status ? "invalid" : "" )} placeholder="Email" name="email" value={this.state.login} onChange={(e) => this.handleChange(e)} required/>
                {this.state.UsernameError.Status ? <div className="error">{this.state.UsernameError.Text}</div> : <></>}
                <input className="create-input input-spacing"  type="password" placeholder="Password" name="password" value={this.state.password} onChange={(e) => this.handleChange(e)} required/>
                <div className="checkbox-div">
                    <input   type="checkbox" className={'accept-checkbox '} id="email_check" name="checkbox" onChange={(e) => this.handleChange(e)}/><label className="accept-label" for="email_check"><a href="/termsandconditions"> Accept Terms</a></label>
                </div>
                {this.state.TermsError.Status ? <div className="error">{this.state.TermsError.Text}</div> : <></>}
                <button className="landing-button hoverable button-main sign-up-button" onClick={(e) => this.handleSubmit(e)}>Sign Up</button> 
            </form>
          </div>

          <div className="overlay-container">
            <div className="overlay">
              
              <div className="overlay-panel overlay-left">
                <div className="form-header off-panel">Welcome!</div>
                <p className="login-description">Enter your personal details and start journey with us</p>
                <button className="ghost button-main" id="signIn" onClick={()=> {this.changePanel()}}>Sign In</button>
              </div>

              <div className="overlay-panel overlay-right">
                <div className="form-header off-panel">Welcome!</div>
                <p className="login-description">Enter your personal details and start journey with us</p>
                <button className="ghost button-main" id="signUp" onClick={()=> {this.changePanel()}}>Sign Up</button>
              </div>
            
            </div>
          </div>

    </div>


  </>);
}
}

const mapStatetoProps = state => {
  return{
    activePanel: state.activePanel,
    nextPage : state.nextPage
  }
}

const mapDispatchtoProps = dispatch => {
  return{
    Authorize: () => {
      dispatch({ type: "AUTHORIZE"});
  },
  SetReduceTutorial: (tut) => {
    dispatch({ type: "SETTUTORIAL" , tutorial : tut});
  },
  SetUsername: (user) => {
    dispatch({ type: "SETUSERNAME" , username : user});
  },
  SetNextPage: (nextPage) => {
    dispatch({ type: "SETNEXTPAGE" , nextPage : nextPage});
  }

}
} 









export default withRouter(connect(mapStatetoProps , mapDispatchtoProps)(Login));





