import React, { Component } from "react";
import axios from 'axios'

import { NavLink } from 'react-router-dom';
import "../Create_Set/CreateSet.scss"
import DragAndDrop from './DragAndDrop'
import request from "superagent";
import auth from "../../Auth/auth";
import logo from "../../images/img_techucateNoWhite.png"; 

import AutomaticChecker from "./AutomaticChecker";
import './AutomaticGenerator.scss'
import TitleBar from "../../Header-Footer/TitleBar";
import  "./AutomaticGenerator.scss";
import { FaCloudUploadAlt} from "react-icons/fa";
import { API_URL } from "../../env";
import Footer from '../../Header-Footer/Footer';
import CreateSet from "../Create_Set/CreateSet"

import reducer from "../../Auth/reducer"


  class AutomaticGenerator extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = { SetName : '' , FileName : '' , Submitted : false , Valid : '',
       files: [
            ''
            
          ] };


    }

    handleChange(event) {

      const val = event.target.value;

      this.setState({SetName : event.target.value , Valid : val != "" ? "" : "invalid"  , "FileError" : ""});
    }

     handleDrop = (files) => {
       if(this.state.SetName != "")
       {

          if(files[0].size > 10000000)
          {
            this.setState({Valid : "invalid" , "FileError" : "File size must be less than 10MB"})
            return;
          }
          const data = new FormData() 
          data.append('file', files[0]);
          data.append('name', files[0].name);
          
          data.append('set_name', this.state.SetName );
          this.setState({FileName : files[0].name});

          
          axios.post(API_URL + '/parser/parsefile', data, {
              onUploadProgress: (ProgressEvent) => {
                  let progress = Math.round(
                  ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                
              }
          }).then(res => {

              this.setState({Submitted : true , files: [''] })
     
            }
              )

        .catch(err => {
        if(err.response)
        {
          if(err.response.status == 409)
          {
            reducer.dispatch({action : "DEATUHROIZE"})
            reducer.dispatch({action : "SETTUTORIAL" , username : ""})

            const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
              this.props.history.push("/app");
            });
          }
          this.setState({"FileError" : err.response.data.message})
        }
          
        });
        
      }
       else{
         this.setState({Valid : "invalid" , "FileError" : "Please enter a set name"})
       }
       
      }
        


    render() {
        return (
           <div className='view-wrapper create-wrapper'>

            <TitleBar selected="/automatic" ></TitleBar>
            {!this.state.Submitted ? <>
            <div className="section-title">Automatic Generator</div></>
          : <></>}
        <div className="Rest">

          {/* {this.state.Submitted ? <CreateSet SetName={this.state.SetName} FileName={this.state.FileName} ></CreateSet> : */}
          {this.state.Submitted ? <CreateSet parsed={true} SetName={this.state.SetName} FileName={this.state.FileName} ></CreateSet> :           
          <div style={{textAlign: "center" , width: "100%"}}>  
              <div className="generator-instructions">Enter a Set Name and Drag and Drop Your Notes Files Below<br></br> We Will Automatically Generate a Set For You </div>    
              <div className="generator-subtext">Maximum file size 10MB. Accepted formats are .txt .pdf .pptx .docx</div>
              <div style={{textAlign: "center" , width: "100%" , "color" : "red"}} className="generator-subtext"> {this.state.FileError}</div>

              <div className="generator-setname-wrapper">
              <input type="text" className={"setname-input " + this.state.Valid }name="SetName" placeholder="Set Name" onChange={this.handleChange}/>
              </div>
              <DragAndDrop className="drag-and-drop" handleDrop={this.handleDrop}>
 
            </DragAndDrop>
            
          </div>
          
        
       
        
        
        }
        
       
        </div>
        {/* {this.state.Submitted ? <></> : <Footer></Footer>} */}
        
        </div>
        );
      }
  }
    
    


  





  export default AutomaticGenerator;


    