import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import "../Card/Card.css"

class Set extends Component{
    constructor(props){
        super(props);
        this.SetName = this.props.SetName;
        this.SetCount = this.props.SetCount;


    }

    render(){
        return(
            
        <tr className="trow"><td className='card'>
                {this.SetName}
                <p>Card Count: {this.SetCount}
                </p>
                
                </td>
                    </tr>
                    
        );
        
    }
}
export default withRouter(Set); 