import React , { Component }  from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";
import {connect} from "react-redux"


class ProtectedRoute extends Component {


    constructor(props , context){
      super(props , context);

      this.state = {'loading' : false, 'recheck' : 0 }
      this.updateTimer = null;
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
}
componentDidMount(){

}



 render()
 {

    if(!this.props.loaded){
      this.updateTimer = setInterval(() => window.location.reload(), 1000);
      return(<>Loading...</>)
    }
    else{
      if(this.props.auth)
      {
        if(this.updateTimer != null)
        {
      
            clearInterval(this.updateTimer);
        
        }
        // return( <this.props.component/>);
        return <Route path={this.props.path} render={(props)=>  <this.props.component {...props}/>} exact={this.props.exact} />;
      }
      else{
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: this.props.location
              }
            }}
          />
        );
      }
    }
 }
};


  
const mapStatetoProps = state => {
  return{
    auth : state.auth,
    loaded : state.loaded
  }
}

const mapDispatchtoProps = dispatch => {

return{

}

} 




export default connect(mapStatetoProps , mapDispatchtoProps)(ProtectedRoute);


          
