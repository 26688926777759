import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import auth from "../Auth/auth";

import axios from 'axios';
import { API_URL } from '../env';


// import logo from "../images/12163_Flippso_PD-02.jpg"

import SetList from "../Dashboard/View_Set/SetSelection/SetList"
// import "./Login/css_landing_page.css";
import "./css_title_bar.css";
import { render } from "@testing-library/react";
import {connect} from "react-redux"


class TitleBar extends Component{
    constructor(props){
        super(props);
        this.selected = this.props.selected;
        if(this.selected == "login")
        {
          
          this.selected = this.props.nextPage;
        
        }

        this.state = {"loading" : true , "authenticated" : false , 'selected' :this.selected }


    }
  componentDidMount(){

    }
changeSelected(i)
{
    this.selected = i;
    
}
handleLogout()
{
  this.props.SetUsername("");
  this.props.logout();
  const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
    this.props.history.push("/app");
 
})
}

render(){
  return (
    <>
    
    <>
    
    <div className={"title-grid " + (this.props.auth ? "spaced" : "")}>
      {/* <img src={logo} className="landing-image"></img> */}
      <NavLink to="/" className={"title-font"}>
          <div className="title-font-white">
          F l i p p s o
          </div>
          <div className="title-font-flashcards">
          f l a s h c a r d s
          </div>
          

          </NavLink>
      {/* <div className="title-logo">
        <img className="logo" src={logo} alt="Techucate Logo"></img> 
      </div> */}



            <div className="links-no-auth">

            
            <NavLink to="/app" exact className={"title-bar-text title-my-sets " + (this.state.selected == "/app" ? "title-underline" : "")} onClick={() => this.props.SetNextPage("/app")}>My Topics</NavLink>
            <NavLink to={"/createset/" + "none"} exact className={"title-bar-text title-create-set " + (this.state.selected =="/createset/none" ? "title-underline" : "")} onClick={() => this.props.SetNextPage("/createset/none")} >Create</NavLink>
            <NavLink to="/automatic" exact className={" title-bar-text title-automatic-generator " + (this.state.selected == "/automatic" ? "title-underline" : "")} onClick={() => this.props.SetNextPage("/automatic")}>Generate</NavLink>
            <NavLink to="/about" exact className={"title-bar-text title-about " + (this.state.selected == 'About' ? "title-underline" : "")}>About</NavLink>
            
            
            {this.props.auth ? <>
            <NavLink to="/" exact className="title-bar-text title-contact" onClick={() => this.handleLogout()}>Logout</NavLink>
            </>
            :
            <NavLink to="/login" exact className="title-bar-text title-contact">Login</NavLink>

    
            }
            </div>
            
    </div> 
 
    </>
 
    </>
  );



}
}

const mapStatetoProps = state => {
  return{
    auth: state.auth,
    nextPage: state.nextPage,
  }
}
const mapDispatchtoProps = dispatch => {
  return{

  logout: (user) => {

    dispatch({ type: "DEAUTHORIZE" });
    
    
  },
  SetUsername: (user) => {
    dispatch({ type: "SETUSERNAME" , username : user});
  },
  SetNextPage: (nextPage) => {
    dispatch({ type: "SETNEXTPAGE" , nextPage : nextPage});
  }

}
} 









export default connect(mapStatetoProps , mapDispatchtoProps)(TitleBar);
// export default TitleBar; 
