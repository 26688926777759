import React, { Component } from '../../../node_modules/react';
import { checkPropTypes } from '../../../node_modules/prop-types';
import axios from '../../../node_modules/axios'
import Card from '../Card/Card.scss'
import TitleBar from '../../Header-Footer/TitleBar';
import {API_URL} from '../../env'
import "./ViewSet.scss"
import "./Circular_Progress.css"
import ViewSetHelper from './ViewSetHelper'
import auth from '../../Auth/auth'
import { CircularProgressbar } from 'react-circular-progressbar';
import { IconContext } from "react-icons";
import ProgressBar from 'react-bootstrap/ProgressBar'
import {MdBook , MdCheck , MdClose} from 'react-icons/md'
import {CardTable} from './CardTable'
import { NavLink } from 'react-router-dom';
import {FiBookOpen} from 'react-icons/fi'
import {FaPencilAlt   , FaArchive} from 'react-icons/fa'
import {MdDelete } from 'react-icons/md'
import Footer from '../../Header-Footer/Footer';
import Modal from 'react-awesome-modal';
import TypeStudy from './TypeStudy/TypeStudy'
import ViewSideBar from './ViewSideBar'
import { Progress } from 'semantic-ui-react'
import Api from '../../services/api'
import reducer from "../../Auth/reducer"
import DeletePopup from './DeletePopup';






axios.defaults.withCredentials = true;

class ViewSet extends Component {

  constructor(props)
  {
    super(props);
  
    if(props.match == undefined)
    {
      this.setID =""
    }
    else{
      this.setID = props.match.params.id;
    }
  
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.closeDeletePopup = this.closeDeletePopup.bind(this);
    this.state = {"DeletePopup": false , 'SetName': '' , 'ShowTable' : true , 'Loading' : 0 , 'Cards' : [] , "LastDate" : "" , "LastAccuracy" : 0, 'FailedCards' : [] , 'CorrectCards' : [] , 'DisplayIndex' : 0 , 'StudyIconClass' : '' , "Progress" : 0 , "StudyMode" : "off" , "Mode" : "View"};
 


  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // make api call to get the cards
    Api.getCards(this.setID, (cardArray, setName) => {
      let x = cardArray.slice()
      this.setState((prevState)=> ({'Loading' : prevState.Loading + 1 , 'Cards' : cardArray.slice() , 'FailedCards' : cardArray , 'SetName' : setName}));  
    });
    Api.getSetData(this.setID, (res) => {

      this.setState((prevState)=> ({'Loading' : prevState.Loading + 1 , 'DueDate' : res.data.Due_Date ? res.data.Due_Date.split("T")[0] : "" , 'LastStudyDate' : res.data.Study_Date ? res.data.Study_Date.split("T")[0] : "" , 'LastAccuracy' : parseInt(res.data.LastAccuracy * 100)}));  
    });
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }


  updateWindowDimensions() {
    const width = window.innerWidth;
    var popupWidth = "400px";
    if(width < 460)
    {
        popupWidth = "300px";
    }
    
    this.setState({ "popupWidth" : popupWidth });
  }

  changeCard(i)
  {
    const cardArray = this.state.FailedCards;
    if(i + this.state.DisplayIndex < cardArray.length && this.state.DisplayIndex + i >= 0)
    {
      this.setState((prevState) => ({'DisplayIndex' : prevState.DisplayIndex + i}));
    }
    
  }

  toggleStudyMode()
  {
    if(this.state.StudyMode == "off")
    {

      this.setState((prevState) => ({'StudyMode' : "on" , 'StudyIconClass' : 'icon-active' , 'FailedCards' : prevState.Cards.slice() , 'CorrectCards' : [] , "DisplayIndex" : 0 , "ShowTable" : false , "Progress" : 0}));

    }
    else{

      this.setState((prevState) => ({'StudyMode' : "off" , 'StudyIconClass' : '' , 'FailedCards' : prevState.Cards.slice()  , 'CorrectCards' : [] , "DisplayIndex" : 0 , "ShowTable" : true}));

    }
  }




  checked(bool)
  {
    if(this.state.StudyMode == "hold")
    {

      if(bool)
      {
        this.setState((prevState) => ({"FailedCards" : prevState.CorrectCards, "CorrectCards" : [], "StudyMode" : "on" , "StudyIconClass" : "icon-active" , "ShowTable" : false , "Progress" : 0 }))
      }
      else{
        this.setState((prevState) => ({"FailedCards" : prevState.CorrectCards , "CorrectCards" : [], "StudyMode" : "off" , "StudyIconClass" : "" , "ShowTable" : true , "Progress" : 100 }))
      }
    }
    else{

      

      if(bool)
      {

        
        var CorrectCards = this.state.CorrectCards;
        var FailedCards = this.state.FailedCards;
        var card =  FailedCards.splice(this.state.DisplayIndex , 1);
        card.CorrectCount += 1;
        CorrectCards.push(card[0]);
        

        

        if(FailedCards.length == 0)
        {
          // axios.post(API_URL  + '/cards/updateset', {'Cards': this.CorrectCards  , 'SetName': this.state.SetName , 'SessionID': auth.getSession() , "set_id" : this.setID })


          
          this.setState((prevState) => ({"FailedCards" : [{"Term" : "You've finished the set, would you like to review it again?" , "Definition" : "You've finished the set, would you like to review it again?" }] , "StudyMode" : "hold" , "StudyIconClass" : "" , "ShowTable" : false , "Progress" : 100 }))
        }
        else{
          
          this.setState((prevState) => ({"FailedCards": FailedCards , "CorrectCards" : CorrectCards , "DisplayIndex" : prevState.DisplayIndex  , "Progress" : Math.floor(100 * CorrectCards.length / (FailedCards.length + CorrectCards.length) ) }));

        }
      



      }
      else{
        var FailedCards = this.state.FailedCards;
        var card = FailedCards.splice(this.state.DisplayIndex , 1);

        card.MissedCount += 1;
        FailedCards.push(card[0])

        this.setState({"FailedCards": FailedCards });
        


      }
    }
  }


  openDeletePopup(){
    this.setState({"DeletePopup" : true});
  }
  closeDeletePopup(){
    this.setState({"DeletePopup" : false});
  }


  render(){
    if(this.state.Loading < 2)
    {
      return(
        <div className='view-wrapper'>
          <TitleBar ></TitleBar>
          <h3>Loading your set please wait</h3>
          <Footer></Footer>
        </div>
        
      );
    }
    else
    {
      return(
  
        <div >

          <TitleBar selected="My Sets" ></TitleBar>
          <div className="card-grid">
            <div className="side-bar-wrapper">
            <ViewSideBar SetName={this.state.SetName} SetID={this.SetID} LastProgress={this.state.LastAccuracy} LastDate={this.state.LastStudyDate} DueDate={this.state.DueDate} openDeletePopup={this.openDeletePopup.bind(this)} setID ={this.setID} Mode=""></ViewSideBar>
          </div>
            
            <div className="cards">

            <div className="hoverable card">
                <div className="card__side card__side--front card__side--front-1">
                    <div className="card__description" style={{"font-size" : ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].term)}}>{this.state.FailedCards[this.state.DisplayIndex].term}</div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                    <div className="card__description" style={{"font-size" : ViewSetHelper.getMainFont(this.state.FailedCards[this.state.DisplayIndex].definition)}}>{this.state.FailedCards[this.state.DisplayIndex].definition}</div>

                </div>

            </div>



            
          
            <div className="card-counter">
                  <div className="hoverable arrow left" onClick={() => this.changeCard(-1)}>&lt; </div> 
                  <div className="index">{this.state.DisplayIndex + 1} / {this.state.FailedCards.length}</div> 
                  <div className="hoverable arrow right" onClick={() => this.changeCard(1)}>&gt; </div>
            </div>
            </div>

          
          <div className="card-table-wrapper" ><CardTable Cards={this.state.Cards}></CardTable></div>


      

            




        

       </div> 

          <DeletePopup DeletePopup={this.state.DeletePopup} closeDeletePopup={() => this.closeDeletePopup()} SetID={this.setID}></DeletePopup>
          {/* <Footer></Footer> */}

        </div>
  
      );
    }
  }
    
  


};

export default ViewSet;

/* <table style={{  borderSpacing: "0 15px"}}>
<tbody>
  <tr><td className="divtab">{this.SetName}</td></tr>

  {this.state.cards.map((card , i) => (
      <Card term={card.Term} definition={card.Definition} key={i} index={i} />
    ))}
  
    </tbody>
</table> */