
// import { addUser } from "../../../TechucateServer/login/login.controller";
import { API_URL } from "../env";
import publicIp from "public-ip";
import auth from "../Auth/auth";
import reducer from "../Auth/reducer"
const axios = require('axios');

axios.defaults.withCredentials = true;



class Api
{
  
  async addUser(data , cb , failcb)
  {
    axios.post(API_URL + "/login/adduser", data ).then(res => cb(res)).catch(err => failcb(err));
  }

  async getSets( cb , failcb = (err) => {return})
  {
    axios.get(API_URL  + '/cards/getsets',   ).then(res => {   
      var setArray = [];
      var topicArray = [];

      
      for (var index = 0; index < res.data.Sets.length; index++) { 
          setArray.push({'SetName' : res.data.Sets[index].set_name , 'SetCount' : res.data.Sets[index].set_count , 'SetID' : res.data.Sets[index].set_id , 'Topic' : res.data.Sets[index].set_topic =="" ? "No Topic" : res.data.Sets[index].set_topic , "accuracy" : res.data.Sets[index].accuracy , "DueDate":  res.data.Sets[index].due_date});

          if(res.data.Sets[index].recommended == true)
          {
              setArray.push({'SetName' : res.data.Sets[index].set_name , 'SetCount' : res.data.Sets[index].set_count , 'SetID' : res.data.Sets[index].set_id , 'Topic' : "Recommended" , "accuracy" : res.data.Sets[index].accuracy});
          }
      }
      for (var index = 0; index < res.data.Topics.length; index++) { 
          topicArray.push({'Topic' : res.data.Topics[index].topic_name , 'Topic_Color' : res.data.Topics[index].topic_color , 'Topic_Symbol' : res.data.Topics[index].topic_symbol ,  'Topic_Class' : "" });  
      }
      cb(topicArray , setArray);
    }).catch(err => {
      if(err.response)
      {
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));
          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            window.location.reload();
          });
        }
      }
      failcb(err)
    });
  }

  async getCards(setID , cb , failcb = (err) => {return})
  {
    axios.get(API_URL + '/cards/getcards', {params: {'set_id' : setID}} ).then(res => {   
      var cardArray = [];       
      for (var index = 0; index < res.data.cards.length; index++) { 
          cardArray.push({'term' : res.data.cards[index].term , 'definition' : res.data.cards[index].definition ,  'accepted' : true  , 'input' : 'input-' + index.toString(), "i" : index} );
      }
      
      cb(cardArray , res.data.setName);
    }).catch(err => {
      if(err.response)
      {
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));

          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  }

  // data = { "docId"}
 /* async getPowerpoint(data , cb , failcb = (err) => {return})
  {
    axios.get(API_URL + '/automatic-parser/getpowerpoint', {params: {'set_id' : setID}} ).then(res => {   
      var cardArray = [];       
      for (var index = 0; index < res.data.cards.length; index++) { 
          cardArray.push({'term' : res.data.cards[index].term , 'definition' : res.data.cards[index].definition ,  'accepted' : true  , 'input' : 'input-' + index.toString(), "i" : index} );
      }
      
      cb(cardArray , res.data.setName);
    }).catch(err => {
      if(err.response)
      {
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));

          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  } */

  async addSet(data , cb , failcb = (err) => {return})
  {

      axios.post(API_URL  + '/cards/addset', data)
          .then((res) => {
            cb(res);
          })
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                reducer.dispatch(this.Deauthorize());
                reducer.dispatch(this.Username(""));
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
            failcb(err)
          });

  }

  async logEvent(data , cb , failcb = (err) => {return})
  {
      


      data.ip =  await publicIp.v4({fallbackUrls: [ "https://ifconfig.co/ip" ]});
      axios.post(API_URL  + '/misc/logevent', data)
          .then((res) => {
            cb(res);
          })
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                reducer.dispatch(this.Deauthorize());
                reducer.dispatch(this.Username(""));
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
            failcb(err)
          });

  }


  async getSetData(setID , cb , failcb = (err) => {return})
  {

    axios.get(API_URL + '/cards/getsetdata', {params: {'set_id' : setID}} ).then(res => {  
            cb(res);
          })
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                reducer.dispatch(this.Deauthorize());
                reducer.dispatch(this.Username(""));
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
            failcb(err)
          });

  }

  async updateAccuracy(data , cb , failcb = (err) => {return})
  {
      var date = new Date();
      var accuracy = 0.0;
      for (var index = 0; index < data.cards.length; index++) {

        if(data.cards[index].MissedCount == 0)
        {
          accuracy += 1.0 / data.cards.length;
        }

    } 
      const dateString = date.toISOString() 

      axios.post(API_URL  + '/cards/updateAccuracy', {"accuracy" : accuracy , "set_id" : data.set_id , "date" : dateString})
          .then((res) => {
            cb(res);
          })
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                reducer.dispatch(this.Deauthorize());
                reducer.dispatch(this.Username(""));
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
            failcb(err)
          });

  }
  async updateSetTopic(data , cb , failcb = (err) => {return})
  {

      axios.post(API_URL  + '/cards/updatesettopic', data)
          .then((res) => {
            cb(res);
          })
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                reducer.dispatch(this.Deauthorize());
                reducer.dispatch(this.Username(""));
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
            failcb(err)
          });

  }


  async updateSet(data , cb , failcb = (err) => {return})
  {

      axios.post(API_URL  + '/cards/updateset', data)
          .then((res) => {
            cb(res);
          })
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                reducer.dispatch(this.Deauthorize());
                reducer.dispatch(this.Username(""));
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
            failcb(err)
          });

  }

  async updateParsedSet(data , cb , failcb = (err) => {return})
  {

      axios.post(API_URL  + '/cards/updateparsedset', data)
          .then((res) => {
            cb(res);
          })
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                // reducer.dispatch({action : "DEATUHROIZE"})
                // reducer.dispatch({action : "SETTUTORIAL" , this.Username : ""})
                reducer.dispatch(this.Deauthorize());
                reducer.dispatch(this.Username(""));
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
            failcb(err)
          });

  }

  async submitTopic(data , cb , failcb = (err) => {return})
  {
    axios.post(API_URL  + '/cards/addtopic', data)
    .then((res) => {
      cb(res);
    })
    .catch(err => {
      if(err.response)
      {
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));

          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  }

  async updateTopic(data , cb , failcb = (err) => {return})
  {
    axios.post(API_URL  + '/cards/edittopic', data)
    .then((res) => {
      cb(res);
    })
    .catch(err => {
      if(err.response)
      {
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));

          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  }

  async deleteTopic(data , cb , failcb = (err) => {return})
  {
    axios.post(API_URL  + '/cards/deletetopic', data)
    .then((res) => {
      cb(res);
    })
    .catch(err => {
      if(err.response)
      {
        console.log(err)
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));

          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  }
  async googleLogin(data , cb , failcb = (err) => {return})
  {
    axios.post(API_URL  + '/login/googlelogin', data)
    .then((res) => {
      cb(res);
    })
    .catch(err => {
      if(err.response)
      {
        console.log(err)
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));

          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  }

  async removeTutorial( cb , failcb = (err) => {return})
  {
    axios.post(API_URL + '/login/removetutorial' ).then(res => {
      cb();
  }).catch(err => {
      if(err.response)
      {
        if(err.response.status == 409)
        {
          reducer.dispatch(this.Deauthorize());
          reducer.dispatch(this.Username(""));


          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  }

  async getParsedCards(data , cb , failcb = (err) => {return})
  {
    axios.post(API_URL  + '/parser/getparsedset', data).then(res => {
      var cardArray = [];

      for (var index = 0; index < res.data.cards.length; index++) { 
          cardArray.push({'term' : res.data.cards[index].term , 'definition' : res.data.cards[index].definition ,   'prediction_type' : res.data.cards[index].prediction_type , 'sentence_number' : res.data.cards[index].sentence_number , 'accepted' : true , 'cardIndex': 'card-' + index.toString()  });

      }


      cb(cardArray);

    })
    .catch(err => {
      if(err.response)
      {
        if(err.response.status == 409)
        {
          reducer.dispatch({action : "DEATUHROIZE"})
          reducer.dispatch({action : "SETTUTORIAL" , username : ""})

          const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
          });
        }
      }
      failcb(err)
    });
  }

  Deauthorize() {
    return {
      type: 'DEATHURIZE'
    }
  }

  Username(text) {
    return {
      type: 'SETUSERNAME',
      username : text
    }
  }
  }



export default new Api();

