import React, { Component } from 'react';
import auth from "../../../Auth/auth";
import Api from "../../../services/api"
import {BsTrash , BsFillPlusCircleFill} from 'react-icons/bs'
import { NavLink } from 'react-router-dom';
import "./SetSelection.scss"
import "./SetSelect.scss"
import "./TutorialPopup.scss"
import '@brainhubeu/react-carousel/lib/style.css';
import Footer from '../../../Header-Footer/Footer';
import EditTopic from './EditTopic';
import SetList from './SetList';
import {connect} from "react-redux"
import TopicHelpers from '../TopicHelpers';
import { IconContext } from "react-icons";
import {BsChevronRight , BsChevronDown  } from 'react-icons/bs'
import {FaPencilAlt  } from 'react-icons/fa'
import Modal from 'react-awesome-modal';
import Carousel, {  slidesToShowPlugin  }  from '@brainhubeu/react-carousel';
import AdComponent from '../../../Utils/adComponent';
import AdSense from 'react-adsense';

import Dropdown from 'react-bootstrap/Dropdown';
import {CirclePicker} from 'react-color';

import './Topic.scss'


class Topic extends Component {
    constructor(props){
        super(props);
        this.key = this.props.Key;

        this.editCalled = false;
        this.state = { "Sets" : this.props.Sets.filter(set=>set.Topic == this.props.TopicName) ,"SetsLength" : this.props.Sets.filter(set=>set.Topic == this.props.TopicName).length.toString(), "TopicIcon" :this.props.TopicIcon, "TopicColor" : this.props.TopicColor , "TopicName" :this.props.TopicName, "circleSize" : 28 , "Small" : false ,
         "Expanded" : this.props.Editable , "Editable" : this.props.Editable, "NewIcon" : "" , "NewColor" : this.props.TopicColor , "NewTopic" : { "Value" : ""  , "Valid" : "" , "Error" : ""} , "Recommended" : this.props.TopicName == "Recommended"};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectOptions = TopicHelpers.getOptions();
        this.dropdownChange = this.dropdownChange.bind(this)
        this.deleteTopic = props.deleteTopic;
        }
          

        
    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

              
    }

    componentDidUpdate(prevProps) {
        var newSets = this.props.Sets.filter(set=>set.Topic == this.state.TopicName)

        if(this.state.Sets.length != newSets.length) 
        {
          this.setState({"Sets" : newSets , "SetsLength" : newSets.length})
        }
      }  
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
  dontShowAgainPopup(){
    this.props.SetReduceTutorial(false);
    
    Api.removeTutorial( () => this.setState({"Tutorial" : false}));  
    
  }
  closeTutorialPopup(){
    this.setState({"Tutorial" : false});
  }



updateWindowDimensions() {
const width = window.innerWidth;
var circleSize = 28;
var small = false;
if(width < 500)
{
    circleSize=16;
    small = true;
}
if(width < 480){
    circleSize=14;
    
}
else if(width < 360){
    circleSize=12;
    
}
else if(width < 600){
    circleSize=18;
}
else if(width < 760){
    circleSize=19;
}
else if(width < 1000){
    circleSize=24;
}
else {
}
this.setState({  "circleSize" : circleSize , "Small" : small });
}

   


handleChange(event) {
        const name = event.target.name;
        const val = event.target.value;
        if("NewTopic" == event.target.name)
        {
            this.setState({"NewTopic" : {"Value" : val, "Valid" : "" , "Error" : ""}});
        }
        else{
            this.setState({ [name]: val });
        }    
  }

    changeTopicClass(flipTopic) 
    {
        var topics = this.state.Topics;

        const index = topics.findIndex((topic) => topic.Topic == flipTopic)
        
        for(var i = 0 ; i < topics.length ; i++)
        {
            if(i != index){
                topics[i].Topic_Class = "";
            }
            
        }
        topics[index].Topic_Class = topics[index].Topic_Class == "" ? "selected" : "" ;

        this.setState({"Topics" : topics});
    }

    toggleExpandTopic(){
        this.setState((prevState) => ({"Expanded" : !prevState.Expanded}));
    }
    toggleEditable(){
        this.setState((prevState) => ({"Editable" : !prevState.Editable}));
    }
    dropdownChange(val)
    {
        this.setState({"NewIcon" : val});
    }

    editTopic(top)
    {
        
        this.editCalled = true;

        var topics = this.state.Topics;
        const index = topics.findIndex((topic) => topic.Topic == top);

        if(this.state.Selection === "add-topic" && this.state.TopicName === topics[index].Topic )
        {

            this.changeTopicClass(top);
            this.changeSelection("add-topic");
            return;
        }

        this.setState({"TopicName" : topics[index].Topic , "TopicColor" : topics[index].TopicColor , "Selection" : "add-topic" , "PrevTopic" : topics[index].Topic });
        this.changeTopicClass(top);

    }
    handleColorChange(color){
        if(color != undefined)
        {
            this.setState({ "NewColor" : color.hex.toString()});
        }
    }
    submitTopic(){
        this.setState((prevState) => ({'NewTopic' : {'Value' : prevState.NewTopic.Value , 'Valid' : true , 'Error' : ""}}))
        if(this.state.NewTopic.Value === "" || this.state.NewTopic.Value.replace(/\s+/g, '') === "")
        {
            this.setState((prevState) => ({'NewTopic' : {'Value' : prevState.NewTopic.Value , 'Valid' : false , 'Error' : "Cannot have empty topic name"}}));
            return;
        }
        if(this.state.NewTopic.Value === "Recommended" )
        {
            this.setState((prevState) => ({'NewTopic' : {'Value' : prevState.NewTopic.Value , 'Valid' : false , 'Error' : "Topic name already exists"}}));
            return;
        }
        if(this.state.TopicName != "")
        {
            Api.updateTopic({ 'topic_name': this.state.NewTopic.Value ,  'topic_symbol': this.state.NewIcon ,  'topic_color': this.state.NewColor , 'prev_topic' : this.state.TopicName } ,
            (res) => {
                this.props.ChangeReduceTopic({ 'TopicName': this.state.NewTopic.Value ,  'TopicIcon': this.state.NewIcon ,  'TopicColor': this.state.NewColor , 'NewTopic' : {'Value' : '' , 'Valid': true , 'Error' : ''}  , 'Editable' : false} , this.state.TopicName )
                this.setState((prevState) => ({ 'TopicName': prevState.NewTopic.Value ,  'TopicIcon': prevState.NewIcon ,  'TopicColor': prevState.NewColor , 'NewTopic' : {'Value' : '' , 'Valid': true , 'Error' : ''}  , 'Editable' : false}))
            } , (err) => {

                this.setState((prevState) => ({'NewTopic' : {'Value' : prevState.NewTopic.Value , 'Valid' : false , 'Error' : err.response.data.Message}}));
            })
        }
        else{
            
            Api.submitTopic({ 'topic_name': this.state.NewTopic.Value ,  'topic_symbol': this.state.NewIcon ,  'topic_color': this.state.NewColor  } ,
            (res) => {
                this.props.AddReduceTopic({ 'TopicName': this.state.NewTopic.Value ,  'TopicIcon': this.state.NewIcon ,  'TopicColor': this.state.NewColor , 'NewTopic' : {'Value' : '' , 'Valid': true , 'Error' : ''}  , 'Editable' : false})
                this.setState((prevState) => ({ 'TopicName': prevState.NewTopic.Value ,  'TopicIcon': prevState.NewIcon ,  'TopicColor': prevState.NewColor , 'NewTopic' : {'Value' : '' , 'Valid': true , 'Error' : ''}  , 'Editable' : false , "SetsLength" : 0}))
                
                // window.location.reload(false);
            } , (err) => {
       
                this.setState((prevState) => ({'NewTopic' : {'Value' : prevState.NewTopic.Value , 'Valid' : false , 'Error' : err.response.data.Message}}));
            })
        }        
    }

    render()
    {
    return(
        <>
        {this.state.Editable ?
            <div className="topic-wrapper hoverable" style={{"backgroundColor" : this.state.NewColor}}>
                <input className={"select-add-topic input " + this.state.NewTopic.Valid } style={{"backgroundColor" : this.state.NewColor}} type="text" value={this.state.NewTopic.Value} name="NewTopic" placeholder="topic name" onChange={this.handleChange}/>
                {!this.state.NewTopic.Valid ? <div className="topic-input-error">{this.state.NewTopic.Error}</div> : <></>}
                <div className="delete-topic">
                <IconContext.Provider value={{ className: "trash-topic-icon" }}>
                           <BsTrash onClick={ () => {this.deleteTopic(this.key)}}></BsTrash>
                    </IconContext.Provider >
                </div>
            </div> 

            :
            <div className="topic-wrapper hoverable" style={{"backgroundColor" : this.state.TopicColor}} onClick={ () => {this.toggleExpandTopic(); }} >   
                <div className="topic-left">

                    <IconContext.Provider value={{ className: "topic-icon" }}>
                            {TopicHelpers.getTopicIcon(this.state.TopicIcon)}
                    </IconContext.Provider >
    
                    <div className="topic-title">
                        {this.state.TopicName}
                    </div>
                </div>
                <div className="topic-right">
                    <div className="topic-expand">
                        <IconContext.Provider value={{ className: "topic-chevron" }}>
                            {this.state.Expanded ? 
                                <BsChevronDown onClick={ () => {return null;} }> </BsChevronDown>
                                :
                                <BsChevronRight onClick={ () => {return null;} }> </BsChevronRight>
                            }
                            </IconContext.Provider>
                    </div>
                    <div className="topic-title" key={this.state.Sets.length.toString()} >
                            {this.state.SetsLength} SETS
                    </div>   
                </div>   
            </div>
        }
        
    
        {this.state.Expanded ?
                    <>
                    {this.state.Editable ?
                    <>
                        <div className="edit-topic-wrapper">
                            <Dropdown className="select-icon-dropdown">
                        <Dropdown.Toggle split id="select-topic-input" className={(this.state.NewIcon != "" ? "" : "no-icon")}>
                            {this.state.NewIcon != "" ? TopicHelpers.getTopicIcon(this.state.NewIcon): <div className="no-icon-wrapper">No Icon</div>}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="topic-dropdown select-dropdown">
                            {this.selectOptions.map(( topic , key ) =>  {                 
                                        return(
                                        <Dropdown.Item key={key} name="SetTopic" className="select-modifier menu-topic-text" val={topic.value} onClick={(e) => {e.preventDefault(); this.dropdownChange(topic.value)}}>
                                            {topic.label}
                                        </Dropdown.Item> )
                                    }
                                )}

                        </Dropdown.Menu>
                    </Dropdown>


                    <div className="color-picker-wrapper">
                        <span className="color-picker-text">Choose color:</span>
                        <CirclePicker circleSize={this.state.circleSize} className='topic-color-picker' colors={["#FCAB10" , "#20C294" ,"#395780" ,"#98A886" , "#5899E2" , "#FF494E" , "#c233ff" , "#ff5d88", "#9f9f9f" ,"#5fab6a"   , "#363457" , "#735290"  ]} name='TopicColor'  onChange={color => this.handleColorChange(color)}></CirclePicker>
  
                    </div>
                    <div className="edit-topic-submit">
                        <button className="hoverable button-main" onClick={ () => this.submitTopic() }>Submit topic</button>
                    </div>

                        </div> 
                        {this.state.TopicName != "" ? <div className="hoverable edit-topic-link" onClick={ () => {this.toggleEditable();}}>Cancel Edits</div> : <></>}
                        </>
                        : 
                         !this.state.Recommended ? <div className="hoverable edit-topic-link" onClick={ () => {this.toggleEditable();}}>Edit topic</div> : <></> 
                    }

                 {this.state.TopicName != "" ? <SetList TopicName={this.state.TopicName}></SetList> : <></>}
           
                    </>
            :
            <></> 
        }

        </>

                       
     
                    

                );
   
      
    
  };

  
  } 
  
  
  
  const mapStatetoProps = state => {
    return{
      Sets : state.Sets
    }
  }
  
  const mapDispatchtoProps = dispatch => {
    return{

    SetReduceTopics: (tut) => {
        dispatch({ type: "SETTOPICS" , Topics : tut});
      },
    AddReduceTopic: (tut) => {
    dispatch({ type: "ADDTOPIC" , Topic : tut});
    },
    ChangeReduceTopic: (top , prev) => {
        dispatch({ type: "EDITTOPIC" , Topic : top , Prev : prev});
        },
    SetReduceSets: (tut) => {
    dispatch({ type: "SETSETS" , Sets : tut});
    },


  
  }
  } 
  
  
  
  
  
  
  
  
  
  export default connect(mapStatetoProps , mapDispatchtoProps)(Topic);

