import React, { Component } from '../../../node_modules/react';

import axios from '../../../node_modules/axios'

import {API_URL} from '../../env'
import "./ViewSet.scss"
import "./Circular_Progress.css"

import Modal from 'react-awesome-modal';
import { withRouter } from 'react-router-dom';
import reducer from "../../Auth/reducer"








class DeletePopup extends Component {
    constructor(props){
        super(props);
        this.SetID = props.SetID;
        this.state ={"DeletePopup" : props.DeletePopup , "popupWidth" : "400px" }
    }
    closeDeletePopup(){
        this.props.closeDeletePopup();
    }

    componentDidUpdate(prevProps) {
        if(this.props.DeletePopup  !== prevProps.DeletePopup ) 
        {
          this.setState({"DeletePopup" : this.props.DeletePopup})
        }
      }  

    async deleteSet()
    {
    const res = await axios.post(API_URL  + '/cards/deleteset', { 'SetID': this.SetID   })
    .then(function(res){

    } )
    .catch(err => {
        if(err.response)
        {
        if(err.response.status == 409)
        {
            reducer.dispatch({action : "DEATUHROIZE"})
            reducer.dispatch({action : "SETTUTORIAL" , username : ""})

            const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
            this.props.history.push("/app");
            });
        }
        }

    });

    this.props.history.push("/app")

    }
    
    
    render()
    {
        return(
           

            
            <Modal visible={this.state.DeletePopup}  width={this.state.popupWidth} height="250px" effect="fadeInDown" onClickAway={() => this.closeDeletePopup()}>
            <div className="delete-popup">
                <div className="delete-popup-header">
                Are you sure you want to delete this set?
                </div>
                <div className="popup-button-wrapper">
                <button className="popup-button" style={{"borderColor" : "green"}} onClick={() => this.deleteSet()}>Yes, I'm sure</button>
                <button className="popup-button" style={{"borderColor" : "red"}} onClick={() => this.closeDeletePopup()}>No</button>
                
                </div>

            </div>
        </Modal>
   
        )
    }

}

export default withRouter(DeletePopup);