import React, { Component } from 'react';
import logo from "../../images/img_techucateNoWhite.png"; 
// import "../../Login/css_landing_page.css";
import {BsTrash , BsUpload ,  BsCircleFill , BsFillPlusCircleFill} from 'react-icons/bs'
import {FaPlus} from 'react-icons/fa'
import {GrDrag} from 'react-icons/gr'
import './CreateSet.scss';
import { withRouter } from 'react-router-dom';
import { DragDropContext, Draggable,Droppable } from 'react-beautiful-dnd';
import auth from "../../Auth/auth";
import axios from "axios"
import TitleBar from '../../Header-Footer/TitleBar';
import { IconContext } from "react-icons";
import {API_URL} from '../../env'
import Footer from '../../Header-Footer/Footer';
import TextareaAutosize from 'react-autosize-textarea';
import ParsedCard from "../Automatic_Generator/ParsedCard";
import Api from "../../services/api"
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import Dropdown from 'react-bootstrap/Dropdown'



  class CreateSet extends Component {
    constructor(props) {
        super(props);

        this.setID = props.match.params.id;
        if(!this.setID)
        {
            this.setID = "none";
        }
      
        this.parsed = props.parsed;
       
        this.FileName = props.FileName;
        var name;
        if(this.parsed)
        {
            name = props.SetName;
            this.SetName = props.SetName;
        }
        else{
            name = "";
        }
        this.state = { SetNameError: "" , values: this.parsed ? [] : [{'term' : '' , 'definition': '' , 'input' : 'input-0'}] ,'SetName' : { "Name" : name  , "Valid" : ""}  , "NewTopic": { "Value" : ""  , "Valid" : "" , "Error" : ""} , "NextVal" : 1 , "SetTopic" : "No Topic" , "Topics" : [] , "Sets" : [] , "Date" : "", "Loading" : (this.setID != 'none' || this.parsed) };
        this.handleChange = this.handleChange.bind(this);
        this.dropdownChange = this.dropdownChange.bind(this);
        this.submitCards = this.submitCards.bind(this);
        this.removeInput = this.removeInput.bind(this);
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
        this.initalizeCallback = this.initalizeCallback.bind(this);
        this.addTopic = this.addTopic.bind(this);

    }
    openDatepicker = () => this._calendar.setOpen(true);
    initalizeCallback(resp )
    {
        var cardArray = [];
                
                
        for (var index = 0; index < resp.data.cards.length; index++) { 
            cardArray.push({'term' : resp.data.cards[index].term , 'definition' : resp.data.cards[index].definition ,  'accepted' : true  , 'input' : 'input-' + index.toString()});
        }
        
        if(this.parsed)
        { 
            
            for (var index = 0; index < resp.data.checkCards.length; index++) { 
                cardArray.push({'term' : resp.data.checkCards[index].term , 'definition' : resp.data.checkCards[index].sentence , 'accepted' : false , 'cardIndex': 'card-check' + index.toString() });
            }
        }

        let x = cardArray.slice()
        this.setState({'Loading' : false , 'values' : cardArray.slice() ,  'SetName' : {"Name" : resp.data.setName  , "Valid" : "" , "Date" : resp.data.date}});  
      
      
    }
    componentDidMount(){

        Api.getSets(
            (topicArray , setArray) => {
                if(this.setID != "none"){
                    const set = setArray.find(x => x.SetID == this.setID);

                    this.setState({'SetName' : {"Name" : set.SetName  , "Valid" : "" } , "SetTopic" : set.Topic,  "Date" : set.DueDate ? set.DueDate.split("T")[0] : ""  }) ; 
                }
                this.setState({ "Sets" : setArray , "Topics" : topicArray });                
            }
        )
        if(this.setID != "none")
        {
            Api.getCards(this.setID, (cardArray) => {
            let x = cardArray.slice()
            this.setState({'Loading' : false , 'values' : cardArray.slice()  });  
            });
        }
        else if(this.parsed)
        {
            
            Api.getParsedCards({ 'set_name' : this.SetName , 'file_name' : this.FileName } , (cardArray ) =>
            {
                let x = cardArray.slice()
        
                this.setState({'Loading' : false , 'values' : cardArray.slice() });  
              
            } )
           
        }
 
        
                
    }
    conponentDidUpdate()
    {
        if (this.textAreaRef) {
            this.textAreaRef.style.height = this.textAreaRef.scrollHeight + "px";
        }
    }
    handleOnDragEnd(result)
    {
        if(result.destination == null)
        {
            return;
        }
        var values = this.state.values;

        [values[result.source.index] , values[result.destination.index]] = [values[result.destination.index],values[result.source.index] ] ;

        this.setState(prevState => ({  values: values   }));
   
    }

    checked(index){
        var cards = this.state.values;
        cards[index].accepted = true;
   
        this.setState({'values' : cards});  

    }


        render() {
            console.log(this.state)
            if(this.state.Loading )
            {   
                if(this.parsed)
                {
                    return(<div className="loading-wrapper">Loading your parsed set. PDFs may take longer than DOCX or TXT documents </div>)
                }
                else{
                    return(<div className='view-wrapper'><TitleBar selected="Create Set"></TitleBar><div className="loading-wrapper">Loading . . . </div></div>)
                }
                
            }

            return(
                
                <>
                <div className='view-wrapper create-wrapper'>
                {
                    this.parsed ? <></> :<>
                
                
               <TitleBar selected="/createset/none"></TitleBar>

               
                </>

                }
               <div className="create-set-top">
                    <div className="create-section-title">Create Set</div>
                    <div className="select-topic-wrapper">
                    <div className="select-topic-description">Select Topic</div>
                    <Dropdown >
                        <Dropdown.Toggle split id="set-topic-input">
                            {this.state.SetTopic}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="topic-dropdown">
                        {this.state.Topics.map(( topic , key ) =>  {                 
                                        if(topic.Topic != this.state.SetTopic){
                                            return(<Dropdown.Item key={topic.Topic} name="SetTopic" className="select-modifier menu-topic-text" val={topic.Topic} onClick={(e) => {e.preventDefault(); this.dropdownChange(topic.Topic)}}>{topic.Topic}</Dropdown.Item> )
                                        }
                                        else{
                                            return(<></>)
                                        }
                                        }
                            )}
                        {this.state.Topics.length > 1 ? <Dropdown.Divider></Dropdown.Divider> : <></>}
                        <div className="add-topic-class">
                            <div className="menu-add-topic-input-wrapper">
                            <input className={"menu-add-topic-input input " + this.state.NewTopic.Valid } type="text" value={this.state.NewTopic.Value} name="NewTopic" placeholder="Create topic" onChange={this.handleChange}/>
                            <div className="menu-add-topic-plus">
                            <IconContext.Provider value={{ className: "hoverable menu-add-topic-plus" }}>
                                                    <FaPlus onClick={ () => this.addTopic()} style={{"color" : "#fff"}}> </FaPlus>
                                </IconContext.Provider>
                             
                            </div>
                            {this.state.NewTopic.Valid  ? <div className={"menu-add-topic-error menu-topic-text"} type="text" >{this.state.NewTopic.Error}</div> : <></>}
                            </div>

                        </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    </div>    

                    <div className="setname-wrapper">
                        <TextareaAutosize className={"setname-input " + this.state.SetName.Valid } type="text" name="SetName" value={this.state.SetName.Name} placeholder="Set Title" onChange={this.handleChange}/>                   
                   </div>
                    <div className="create-date-wrapper">
                    <div className="due-date-entry"> <div className="date-text">Due Date:</div>
                        <input className={"date-input " + this.state.SetName.Valid } type="date" placeholder="Due Date" value={this.state.Date} name="Date" onChange={this.handleChange}/>
                    </div>

                    </div>
                    <div className="create-button-top-wrapper">
                            <button className="hoverable submit-button-top button-main" onClick={ () => this.submitCards() }>Finish</button>
                    </div>
                    <div className="generate-button-top-wrapper">
                            <button className="hoverable generate-button-top button-main" onClick={ () => this.props.history.push("/automatic") }>Auto-Generate <IconContext.Provider value={{ className: "upload" }}><BsUpload></BsUpload>                                     </IconContext.Provider></button>
                    </div>
                </div>

                   <div className="card-body" >
                   <DragDropContext onDragEnd={this.handleOnDragEnd}>
                   <Droppable droppableId="cards">
                   {(provided) => (    

                        <div className="card-drop" {...provided.droppableProps} ref={provided.innerRef}>
                            {this.state.values.map((input , i) =>  (   
                             
                                <Draggable key={input["input"]} draggableId={input["input"]} index={i}>
                                    {(provided) => (  
                                        <div className="input-card"  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <IconContext.Provider value={{ className: "hoverable drag-icon" }}>
                                            <GrDrag onClick={() => this.removeInput(i)}></GrDrag>
                                        </IconContext.Provider>
                                        <div className="input-card-body">
                                            <TextareaAutosize className="input-field input-field-term" type="text" name={`term-${i}`} value={input.term} placeholder="Term"  onChange={this.handleChange}  /> 
                                            <TextareaAutosize className="input-field input-field-definition" type="text" name={`definition-${i}`}  value={input.definition} placeholder="Definition" onChange={this.handleChange}  minrows={1}/>
                                            </div>
                                            <div className="input-card-header">
                                                <IconContext.Provider value={{ className: "hoverable trash-icon" }}>
                                                    <BsTrash onClick={() => this.removeInput(i)}></BsTrash>
                                                </IconContext.Provider>
                                        </div>
                                        </div>
                                        )}
                                    </Draggable>
                                   ))}
                            {provided.placeholder}  
                        </div>
                        )}
                    
                    </Droppable >
                    </DragDropContext>
                    <IconContext.Provider value={{ className: "hoverable plus create-set-plus" }}>
                                                <BsFillPlusCircleFill onClick={ () => this.appendInput() }> </BsFillPlusCircleFill>
                    </IconContext.Provider>
      
                        
                    
                        <div className="create-button-wrapper">
                            <button className="hoverable submit-button button-main" onClick={ () => this.submitCards() }>Finish</button>
                        </div>
                    </div>
                
                
                </div>
                    
             
                
                </>
               
      


         
            );
        }
        dropdownChange(val)
        {
            this.setState({"SetTopic" : val});
        }
        handleChange(event) {
            const name = event.target.name;
            const val = event.target.value;
            console.log(event.target)
            console.log(this.state)
            if("SetName" === event.target.name)
            {
                const valid = val != "" 
                this.setState({'SetName' : { "Name" : val  , "Valid" : valid ? "" : "invalid"}});

            }
            else if("SetTopic" == event.target.name)
            {

                this.setState({"SetTopic" : val});
            }
            else if("NewTopic" == event.target.name)
            {

                this.setState({"NewTopic" : {"Value" : val, "valid" : "" , "Error" : ""}});
            }
            else if("Date" == event.target.name)
            {
                
                this.setState({"Date" : val});
            }
            else{
                const num = parseInt(event.target.name.split('-')[1]);
                var tempArray = this.state.values;

                if("term" === event.target.name.split('-')[0])
                {
                    tempArray[num].term = val;

                }
                else if("definition" === event.target.name.split('-')[0]){
                    tempArray[num].definition = val;
                }
                
                this.setState(prevState => ({ values: tempArray }));

            }
            
          }

        addTopic()
        {
            console.log(this.state.NewTopic);
            if(this.state.NewTopic.Value === "" || this.state.NewTopic.Value.replace(/\s+/g, '') === "")
            {
                this.setState((prevState) => ({"NewTopic" : { "Value" : prevState.NewTopic.Value  , "Valid" : "invalid" , "Error" : "Topic Name Cannot Be Empty"}}));
            }
            if(this.state.NewTopic)
            {
                Api.submitTopic({ 'topic_name': this.state.NewTopic.Value ,  'topic_symbol': "" ,  'topic_color': "#5899E2" } ,
                (res) => {
                    this.setState((prevState) => ({"Topics" : prevState.Topics.concat([{"Topic" : prevState.NewTopic.Value}]) , "NewTopic" : {"value" : "" , "Valid" : "" , "Error" : ""}}))
                } , (err) => {
    
                    this.setState((prevState) => ({"NewTopic" : { "Value" : prevState.NewTopic.Value  , "Valid" : "invalid" , "Error" : err.response.data.Message}}));
                })
            }
        }
        removeInput(itemNumber)
        {   

        
            var values = this.state.values;

            values.splice(itemNumber , 1);
            this.setState(prevState => ({  values: values   }));
      
        }

        appendInput() {
            var newValue;
            if(this.parsed)
            {
                newValue = {'term' : '' , 'definition': '' , 'input' : `input-${this.state.NextVal + 1}` , 'accepted' : true , 'prediction_type' : -1 , "sentence_number" : -1};
            }
            else{
                newValue = {'term' : '' , 'definition': '' , 'input' : `input-${this.state.NextVal + 1}` , 'accepted' : true };
            }
            this.setState(prevState => ({  values: prevState.values.concat([newValue])  ,  SetName: prevState.SetName , NextVal: prevState.NextVal + 1  }));
        }

            
        async submitCards() {

            if(this.parsed){
                Api.updateParsedSet({'Cards': this.state.values , 'SetName': this.props.SetName  , "FileName":this.FileName}
                ,  (res) => {return} , (err) => {
                    {return}
                });
            }
            if(this.state.SetName.Name == "" || (this.state.Sets.includes(this.state.SetName.Name) &&this.setID == "none" ) || (this.state.SetName.Name.replace(/\s+/g, '') === ""))
            {
                this.setState((prevState) => ({ 'SetName' : { "Name" : prevState.SetName.Name  , "Valid" : "red-underline"} } ));
            }
            else if(this.setID == "none"){
                Api.addSet( {'values': this.state.values , 'SetName': this.state.SetName.Name ,  'SetTopic': this.state.SetTopic , "Date" : this.state.Date } 
                ,  (res) => this.props.history.push("/app") , (err) => {
                    this.setState((prevState) => ({"SetNameError" : err.response.data.Message , "SetName" : {"Name" : prevState.SetName.Name , "Valid" : "red-underline"} }));
                } )
            }
            else{

                Api.updateSet({'Cards': this.state.values , 'SetName': this.state.SetName.Name ,  "SetTopic" : this.state.SetTopic , 'SetID' : this.setID , "Date" : this.state.Date }
                ,  (res) => this.props.history.push("/app") , (err) => {
                    this.setState((prevState) => ({"SetNameError" : err.response.data.Message , "SetName" : {"Name" : prevState.SetName.Name , "Valid" : "red-underline"} }));
                });
                
            }


        }
        

  };

  export default withRouter(CreateSet);


//   if(this.parsed)
//   {
//       return(
//       <>
//           <div className="create-set-top">
//           <div className="setname-wrapper">
//               <TextareaAutosize className={"setname-input " + this.state.SetName.Valid } type="text" name="SetName" value={this.state.SetName.Name} placeholder="Set Name" onChange={this.handleChange}/>                   
//          </div>
//          <div className="setname-wrapper">
//          {this.state.SetNameError ? <div className="error" style={{"color" : "white"}}>{this.state.SetNameError}</div> : <></>}
//          </div>
//          <div className="setname-wrapper">
//          <select className="set-topic-input" name="SetTopic" placeholder="Set Topic"  onChange={this.handleChange}>
//               <option className="hoverable" value={this.state.SetTopic} style={{"color" : "black"}}>{this.state.SetTopic}</option>
//               {this.state.Topics.map(( topic , key ) => {
                  
             
//                   if(topic.Topic != this.state.SetTopic){
//                       return(<option value={topic.Topic} style={{"color" : "black"}}>{topic.Topic}</option> )
//                   }
//                   else{
//                       return(<></>)
//                   }
//               }
                  
//               )}
              
//               </select>

              
//               <div className="date-text">Due Date:</div>
//               <input className={"date-input " + this.state.SetName.Valid } type="date" placeholder="Due Date" value={this.state.Date} name="Date" onChange={this.handleChange}/>

//       </div>
//       </div>
    

//       <div className="input-body">


//               <div className="mid-section-title"> Please Review The Set We Have Created For You </div> 
    
//               <div className="card-body">
//               {this.state.values.filter(card=>card.accepted).map((card , i) => 
//                   {

//                       if(card.accepted)
//                       {
//                           return (                                 

//                            <ParsedCard term={card.term} definition={card.definition} key={card.cardIndex} checkable={false} cardIndex={card.i} valid={card.valid} handleChange={this.handleChange.bind(this)} checkClick={this.checked.bind(this)} removeInput={this.removeInput.bind(this)}/>
//                           )
//                       }
//                   })
//                   }
//                   <button className="hoverable plus create-set-plus" onClick={ () => this.appendInput() }>+</button>
//                    </div>
              
               
//                       <div className="mid-section-title">We Also Found These terms That Might Be Helpful</div>
         
//               <div className="card-body">
//                   {this.state.values.filter(card=>!card.accepted).map((card , i) => 
//                   {
//                       if(!card.accepted)
//                       {
//                           return (
//                               <ParsedCard term={card.term} definition={card.definition} key={card.cardIndex} checkable={true} cardIndex={card.i} valid={card.valid} handleChange={this.handleChange.bind(this)} checkClick={this.checked.bind(this)} removeInput={this.removeInput.bind(this)} sample={true} />
//                           )
//                       }
//                   })
                 
//                   } 
          
//                   <div className="create-button-wrapper">
//                   <button className="hoverable submit-button" onClick={ () => this.submitCards() }>Create</button>
//                   </div>
//               </div>
              
//               </div>
     
             
//   </>);
//   }


    
