import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import "./Login/css_landing_page.css";
import "./Footer.scss";



class Footer extends Component{
    constructor(){
        super();
        // this.selected = this.props.selected;
        // this.state = {"loading" : true , "authenticated" : false}


    }

render(){

  return (

    
    <div className="footer-grid">
            <div className="footer-header" style={{"gridRowStart":"1"}}>F l i p p s o</div>
            <div className="footer-header"style={{"gridColumnStart":"2"}}>A b o u t</div>
            <div className="footer-header" style={{"gridColumnStart":"3"}}>F l a s h c a r d s</div>
            <div className="footer-links footer-description" style={{"textDecoration": "none"}}>
            <div>Support:</div>
            <div>support@flippso.com</div>
            </div>
            <div className="footer-links-1">

            <NavLink to="/about" exact className="footer-links" >About</NavLink>
            <NavLink to="/termsandconditions" exact className="footer-links" >Terms and Conditions</NavLink>
            <NavLink to={"/privacy"} exact className="footer-links">Privacy Policy</NavLink>
        
            </div>
            <div className="footer-links-2">

            
             <NavLink to={"/app"} exact className="footer-links">My Topics</NavLink>       
            <NavLink to="/create/none" exact className="footer-links" >Create Set</NavLink>
            <NavLink to={"/generate"} exact className="footer-links">Generate Set</NavLink>

            </div>

            
    </div> 
 
  
  );



}
}
export default Footer; 
