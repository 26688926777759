import React, { Component } from 'react';
import axios from 'axios';
import ParsedCard from "./ParsedCard";
import auth from "../../Auth/auth";
// import "../Card/Card.css"
import {API_URL} from '../../env'
import "../Create_Set/CreateSet.scss"
import { NavLink } from 'react-router-dom'
import { element } from 'prop-types';
import Footer from '../../Header-Footer/Footer';

class AutomaticChecker extends Component {
    constructor(props){
        super(props);
        this.SetName = props.SetName;
        this.FileName = props.FileName;
        this.checked = this.checked.bind(this);
        this.cards = [];
        this.checkCards = [];
        this.push = [];
        this.state = {'loading' : true , 'SetName' : { "Name" : props.SetName  , "Valid" : ""} , 'cards' : [] , "SetTopic" : "" ,  "Topics" : []};
        
         
         
        }
    
    componentDidMount() { 

            var resp = axios.post(API_URL  + '/parser/getparsedset', { 'set_name' : this.SetName , 'file_name' : this.FileName } ).then(resp =>
            {

        
                var cardArray = [];
        
                for (var index = 0; index < resp.data.cards.length; index++) { 
                    cardArray.push({'term' : resp.data.cards[index].term , 'definition' : resp.data.cards[index].definition , 'accepted' : true , 'cardIndex': 'card-' + index.toString()  });
        
                }
        
                var checkCards = [];
        
                for (var index = 0; index < resp.data.checkCards.length; index++) { 
                    cardArray.push({'term' : resp.data.checkCards[index].term , 'definition' : resp.data.checkCards[index].sentence , 'accepted' : false , 'cardIndex': 'card-check' + index.toString() });
        
                }

                this.cards = cardArray;
        

                this.setState({'cards' : cardArray , 'loading' : false });





            })


            axios.get(API_URL  + '/cards/getsets' ).then(resp => {
    
                var setArray = [];
                var topicArray = [];
    
                for (var index = 0; index < resp.data.Sets.length; index++) { 
                    setArray.push(resp.data.Sets[index].set_name );
    
                }
    
                for (var index = 0; index < resp.data.Topics.length; index++) { 
                    topicArray.push({'Topic' : resp.data.Topics[index].topic_name  });
    
                }
    
                
    
                this.setState({ "Sets" : setArray , "Topics" : topicArray });   
            
    
            }) ;  
                    
        }
            
      


    checked(index){

        var cards = this.state.cards;
        cards[index].accepted = true;
        this.setState({'cards' : cards});  

    }


    removeInput(index){         



            var cards = this.state.cards;

            const ind = cards.findIndex((el) => (el.cardIndex == index) );
            cards.splice(ind , 1);
            this.setState({ cards: cards   });
      
        }
   
    
    
          
        

    
    async submitSet() {

        if(this.state.SetName.Name == "" || this.state.Sets.includes(this.state.SetName.Name))
        {
            this.setState((prevState) => ({ 'SetName' : { "Name" : prevState.SetName.Name  , "Valid" : "invalid"} } ));
 
        }
        else{
            const res = await axios.post(API_URL + '/cards/addset', {'values': this.cards , 'SetName': this.state.SetName.Name , "SetTopic" : this.state.SetTopic})
            .then(function(res){
   
            } )
            .catch(err => console.log(err.data));
            this.props.history.push("/app")

        }

    }


    handleChange(event) {
        const val = event.target.value;
        if("SetName" === event.target.name)
        {

            const valid = val != "" 
            this.setState({'SetName' : { "Name" : val  , "Valid" : valid ? "" : "invalid"}});
        }
        else{
            const num = parseInt(event.target.name.split('-')[2]);

            var tempArray = this.state.cards;
            

            if("term" === event.target.name.split('-')[0])
            {
                tempArray[num].term = val;

            }
            else if("definition" === event.target.name.split('-')[0]){
                tempArray[num].definition = val;
            }
            
            this.setState({ 'cards': tempArray });

        }
        
    }
        
          
    render() {
        return(
            <>
                <div className="create-set-top">
                <div className="input-body">
                <div className="parser-setname-wrapper">Set Name:</div>
                <div className="input-body">
                   <div className="setname-wrapper">
                        <input className={"setname-input " + this.state.SetName.Valid } type="text" name="SetName" value={this.state.SetName.Name} placeholder="Set Name" onChange={this.handleChange} />
                        <select className="settopic-input" name="SetTopic" placeholder="Set Topic"  onChange={this.handleChange}>
                        <option className="hoverable" value="No Topic">No Topic</option>
                        {this.state.Topics.map(( topic , key ) => (
                            <option value={topic.Topic}>{topic.Topic}</option>
                        ))}
                        
                        </select>
                        <button className="hoverable parser-submit-button" onClick={ () => this.submitSet() }>Create Set</button>

                   </div>
                   </div>
                        
                        {this.state.cards.length == 0 ? "Please wait while we process your file" : 
                        <>
                        <h2>Please Review The Set We Have Created For You </h2> 
              
                            
                        {this.state.cards.filter(card=>card.accepted).map((card , i) => 
                            {

                                if(card.accepted)
                                {
                                    return (                                 

                                     <ParsedCard term={card.term} definition={card.definition} key={card.cardIndex} checkable={false} cardIndex={card.cardIndex} valid={card.valid} handleChange={this.handleChange.bind(this)} checkClick={this.checked.bind(this)} removeInput={this.removeInput.bind(this)}/>
                                    )
                                }
                            })
                            }
                        <div className="potential-card-body">
                         
                                <h2>We Also Found These terms That Might Be Helpful</h2>
                   

                            {this.state.cards.filter(card=>!card.accepted).map((card , i) => 
                            {
                                if(!card.accepted)
                                {
                                    return (
                                        <ParsedCard term={card.term} definition={card.definition} key={card.cardIndex} checkable={true} cardIndex={i} valid={card.valid} handleChange={this.handleChange.bind(this)} checkClick={this.checked.bind(this)} removeInput={this.removeInput.bind(this)}/>
                                    )
                                }
                            })
                            }
                            
                    </div>
                    </>
                    }
                    </div>
             
                </div>
            </>
        );
    }
  }
  
  export default AutomaticChecker;

//   <NavLink to="/app" onClick={ () => this.submitSet()}>Finalize Set</NavLink>