import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import auth from "../Auth/auth";

import TitleBar from '../Header-Footer/TitleBar';
import { API_URL } from '../env';
import './landing-page.scss'
import './About.scss'
import './Feedback.scss'
import reducer from "../Auth/reducer"

axios.defaults.withCredentials = true;
// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class Feedback extends Component {
  constructor(props){
    super(props);
    this.state = { 
      'feedback' : ''


    };
    this.handleChange = this.handleChange.bind(this)
    this.submitFeedback = this.submitFeedback.bind(this)


  }


  handleChange(event) {
            

        this.setState({ 'feedback': event.target.value });

    
    
  }


    
submitFeedback() {

   


        axios.post(API_URL  + '/misc/submitfeedback', {'Feedback': this.state.feedback   })
          .then(function(res){
   
          } )
          .catch(err => {
            if(err.response)
            {
              if(err.response.status == 409)
              {
                reducer.dispatch({action : "DEATUHROIZE"})
                reducer.dispatch({action : "SETTUTORIAL" , username : ""})
      
                const resp = axios.post(API_URL + '/login/logout' ).then((res)=>{
                  this.props.history.push("/app");
                });
              }
            }
    
          });
      
      this.props.history.push("/app")


    }
    




  render(){

    
    return(
      <>
      <TitleBar selected="Feedback"></TitleBar>
    <div className="feedback-wrapper">
      <div></div>
    <div className="feedback-header">We would love to hear feedback from you. Please type below any issues you encountered or new features you'd like to see. Thank you for your input!</div>
    <input className="input-field input-field-feedback" type="text" value={this.state.feedback} placeholder="Submit Your Feedback"  onChange={this.handleChange}></input>

    <div className="create-button-wrapper">
    <button className="submit-button" onClick={ () => this.submitFeedback() }>Submit</button>
    </div>

    </div>
    </>)
  }
}
export default Feedback;