import React, { Component } from 'react';
import auth from "../Auth/auth";
import axios from 'axios';
import logo from "../images/img_techucateNoWhite.png"; 
import "./landing_page.css";
import TitleBar from '../Header-Footer/TitleBar';
import { API_URL } from '../env';
import Footer from '../Header-Footer/Footer';
import { NavLink } from 'react-router-dom';
axios.defaults.withCredentials = true;
// import "../Dashboard/Automatic_Generator/ParsedCard.scss"

class ResetPassword extends Component {
  constructor(props){
    super(props);
  
    if(props.match == undefined)
    {
      this.reset_id = "";
    }
    else{
      this.reset_id = props.match.params.id;
    }


    this.state = { 
   

        "email": '',
        "password": '',
        "passwordMatch": "",
        "valid" : true,
        "loading" : true,

      
      "activePanel": '',
      "UsernameError" : {
        "Text" : "",
        "Status" : false
      }

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }


  componentDidMount(){

    // make api call to get the cards
    axios.get(API_URL + '/reset/checkreset', {params: {'reset_id' : this.reset_id}} ).then(resp => {
      
      this.setState({"valid" : resp.data.valid , "loading" : false});
      

    
    });
  }

  
  handleChange(event) {
    event.preventDefault();
    if(event.target.name == "email")
    {
      this.setState({"UsernameError" : {"Text" : "" , "Status" : false} } );
    }
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  

    if(this.state.password === "" )
    {
      this.setState({      "UsernameError" : {
        "Text" : "Password cannot be empty",
        "Status" : true
      }})
    }
    else if(this.state.password !== this.state.passwordMatch )
    {
      this.setState({      "UsernameError" : {
        "Text" : "Passwords must match",
        "Status" : true
      }})
    }
    else{

      const data = {
        userName : this.state.email,
        password : this.state.password,
        reset_id: this.reset_id
  
      }
        
        axios.post(API_URL + "/reset/resetpassword", data )
        .then(res => {
          this.props.history.push("/login")
          
        }
          )
        .catch(err => {
            this.setState({"UsernameError" : {"Text" : "Error Changing Password" , "Status" : true}}  
            );
        })

    }
    

    
    }
  



render(){

  if(this.state.loading)
  {
    return(<div>Loading...</div>);
  }
  else{

  
    if(this.state.valid)
    {
      return (

        <>
      
      <TitleBar selected="Create"></TitleBar>
      
      
      <div className="reset-body "> 
      
      
                <div className="reset-form-container">
          
                      <div className="form-header">Reset Your Password</div>
                      <div className="login-subtext reset-subtext">Enter your new password below</div>
                      <input type="password" className="input reset-input input-spacing" placeholder="Password" name="password" value={this.state.password} onChange={this.handleChange} required />
                      {this.state.UsernameError.Status ? <div className="error">{this.state.UsernameError.Text}</div> : <></>}
                      <input type="password" className="input reset-input input-spacing" placeholder="Re-Enter Password" name="passwordMatch" value={this.state.passwordMatch} onChange={this.handleChange} required/>
      
                    
      
                      <button className="landing-button reset-form-button button-main" onClick={this.handleSubmit}>Reset Password</button>
                  
            
          </div>
      </div>
      
      
      <Footer></Footer>   
        </>);
    }
    else{
      return (

        <>
      
      <TitleBar selected="Create"></TitleBar>
      
      
      <div className="reset-body"> 
      
      
                <div className="reset-form-container">
          
                      <div className="form-header">This link has expired</div>
                    
                  
            
          </div>
      </div>
      
      
      <Footer></Footer>   
        </>);
    }

  }
  
  }
}












export default ResetPassword;





