
import { createStore } from 'redux';
const axios = require('axios');




const initalState = {
  auth : false,
  activePanel : "",
  tutorial: false,
  username: "",
  loaded: false,
  nextPage: "/app",
  Sets: [],
  Topics: [],
  version: Math.random() > .5 ? "a" : "b"
}

const reduc =  (state = initalState, action) => {
  const newState = {...state}
    
    switch (action.type) {
      case "INITIALIZE":
        newState.auth = action.auth;
        newState.username = action.username;
        newState.tutorial = action.tutorial;
        newState.loaded = true;
        newState.version = action.version;
        return newState;
      case "AUTHORIZE":
        newState.auth = true;
        return newState;
      case "DEAUTHORIZE":
        newState.auth = false;
        return newState;
        case "GETSTARTED":
          newState.activePanel = "right-panel-active";
          return newState;
        case "LOGIN":
          newState.activePanel = "";
          return newState;
          case "SETTUTORIAL":
            newState.tutorial = action.tutorial;
            return newState;
          case "SETUSERNAME":
            newState.username = action.username;
            return newState;
            case "SETNEXTPAGE":
              newState.nextPage = action.nextPage;
              return newState;
          case "SETTOPICS":
            newState.Topics = action.Topics;
            return newState;
          case "ADDTOPIC":
            newState.Topics.push(action.Topic);
            return newState;
            case "EDITTOPIC":
              //tbd
              newState.Sets = newState.Sets.map(x => { 
              if(x.Topic == action.Prev){
                var y = x
                y.Topic = action.Topic.TopicName;
                return y;
              }
              else{
                return x;
              }});
              var top = newState.Topics.findIndex(x => x.TopicName == action.Prev);
              if(top)
              {
                newState.Topics[top] = action.Topic;
              }
              
              return newState;
          case "SETSETS":
            newState.Sets = action.Sets;
            return newState;
      default:
        return newState;
    }
  };

  const reducer = createStore(reduc);


  export default reducer;
